import JwtService from '@/services/jwt.service'
import Vue from 'vue'

export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function setUser (state, user) {
  state.user = user
}

export function setError (state, error) {
  state.errors = error
}

export function startRefreshingToken (state) {
  state.refreshing = true
}

export function endRefreshingToken (state) {
  state.refreshing = false
}

export function setAuth (state, data) {
  state.isAuthenticated = true
  // state.user = user
  state.errors = {}
  JwtService.saveToken(data.token)
  JwtService.saveRefreshToken(data.refresh_token)
}

export function purgeAuth (state, token) {
  state.isAuthenticated = false
  state.user = {}
  state.errors = {}
  JwtService.destroyToken()
}

export function setUserProfilePic (state, pic) {
  Vue.set(state.user, 'profile_pic', URL.createObjectURL(pic))
}

export function setCsrfToken (state, csrfToken) {
  state.csrfToken = csrfToken
}
