
export function getLabDetail (state) {
  return state.labDetail
}

export function getRecentLabs (state) {
  return state.recentLabs
}

export function getLabs (state) {
  return state.labs
}

export function getVpnConnectionStatus (state) {
  return state.vpnConnectionStatus
}

export function isConnectedToVpn (state) {
  return state.vpnConnectionStatus.status
}

export function getCurrentLabMachine (state) {
  return state.currentLabMachine
}

export function isLoading (state) {
  return state.loading
}
