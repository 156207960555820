// import ApiService from '@/services/api.service'

// import URLS from '@/config/urls'

export function pushBreadcrumb (context, step) {
  context.commit('pushBreadcrumb', step)
}

export function clearBreadcrumbs (context) {
  context.commit('clearBreadcrumbs')
}
