export function getCategories (state) {
  return state.categories
}

export function getCategoryDetail (state) {
  return state.categoryDetail
}

export function getWorkRoleDetail (state) {
  return state.workRoleDetail
}

export function getCompletedCategoriesNice (state) {
  return state.completedCategoriesNice
}

export function getCompletedCategoriesMitre (state) {
  return state.completedCategoriesMitre
}

export function isLoading (state) {
  return state.loading
}
