import Vue from 'vue'

export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function setPills (state, pills) {
  state.pills = pills
}

export function setPillDetail (state, pillDetail) {
  state.pillDetail = pillDetail
}

export function setRecentsPills (state, recentPills) {
  state.recentPills = recentPills
}

export function addRevealedHint (state, params) {
  state.pillDetail.vms.forEach(vm => {
    if (vm.id === params.machine.id) {
      vm.hintsRevealed.push(params.hint)
    }
  })
}

export function setPillDetailVms (state, vms) {
  Vue.set(state.pillDetail, 'vms', vms)
}
