export function isLoading (state) {
  return state.loading
}

export function currentUser (state) {
  return state.user
}

export function getErrors (state) { return state.errors }

export function isAuthenticated (state) {
  return state.isAuthenticated
}

export function isRefreshing (state) {
  return state.refreshing
}

export function getCsrfToken (state) {
  return state.csrfToken
}
