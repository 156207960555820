import { getter, postter } from '@/services/store/utils'
import URLS from '@/config/urls'
import ApiService from '@/services/api.service'

export function getUserProgress (context) {
  return getter(context, URLS.API.USER.GET_PROGRESS, 'setUserProgress')
}

export function getObjectivesReport (context) {
  return getter(context, URLS.API.USER.GET_OBJECTIVES_REPORT, 'setObjectivesReportUrl')
}

export function getCareerReport (context) {
  return getter(context, URLS.API.USER.GET_CAREER_REPORT, 'setCareerReportUrl')
}

export function getLabsReport (context) {
  return getter(context, URLS.API.USER.GET_LABS_REPORT, 'setLabsReportUrl')
}

export function getAvailableCertificates (context) {
  return getter(context, URLS.API.CERTIFICATES.GET_AVAILABLE_CERTIFICATES, 'setAvailableCertificates')
}

export function submitNewCertificate (context) {
  return postter(context, URLS.API.USER.ADD_CERTIFICATION)
}

export function stopMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.USER.MACHINE.STOP_OR_UNLOCK(params.machine))
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

// ------------------- Profile Activity -------------------
export function getLastWeekCompletedPills (context, params) {
  context.commit('startLoading')
  return ApiService
    .query(URLS.API.USER.STATS.LAST_WEEK_COMPLETED_PILLS, params)
    .then(response => {
      if (response.status !== 200) {
        throw Error()
      }
      context.commit('endLoading')
      return response.data
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

// ------------------ ISSUES -------------------------------------------
export function submitIssue (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.USER.ISSUES.SUBMIT_ISSUE, params)
    .then(response => {
      if (response.status !== 200 || response.data.status !== 'ok') {
        throw Error()
      }
      context.commit('endLoading')
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}
