export default function () {
  return {
    loading: false,
    userProgress: {},
    objectivesReportUrl: '',
    careerReportUrl: '',
    labsReportUrl: '',
    availableCertificates: []
  }
}
