import { getter } from '@/services/store/utils'
import URLS from '@/config/urls'

export function getCareers (context) {
  return getter(context, URLS.API.CAREERS.GET_CAREERS, 'setCareers')
}

export function getCareerDetail (context, id) {
  return getter(context, URLS.API.CAREERS.GET_CAREER_DETAIL(id), 'setCareerDetail')
}
