export const locale = {
  hello: 'Hola mundo',

  GENERAL: {
    titulo_bienvenida: '¡Bienvenido a academia!',
    hello: 'Hola',
    quick_actions: 'Acciones rápidas',
    go_to_profile: 'Ir al perfil',
    information: 'Información',
    second: 'segundo',
    seconds: 'segundos',
    minute: 'minuto',
    minutes: 'minutos',
    hour: 'hora',
    hours: 'horas',
    points: 'puntos',
    select_one: 'Selecciona uno',
    faqs: 'FAQs',
    faqs_full: 'Preguntas frecuentes',
    no_results: 'No hay resultados',
    no_results_sub: 'Parece que no hay ningún elemento disponible',
    congratulations: '¡Enhorabuena!',
    loading: 'Cargando',
    back_to_home: 'Volver al inicio',
    title_error: '¡Error!',
    actions: 'Acciones',
    home: 'Inicio',
    academy: 'Academia',
    see_more: 'Ver más',
    oops: 'Oops',
    show_more: 'Ver más',
    show_less: 'Ver menos',
    no_selection: 'Sin selección',
    completed_fem: 'Completadas',
    pending: 'Pendientes',

    only_showing_available_pills: 'Mostrando las píldoras accesibles actualmente',
    currentBlockedMachine: 'Máquina en uso',

    LANGS: {
      Spanish: 'Español',
      English: 'Inglés',
      German: 'Alemán'
    },
    COUNTRIES: {
      Spain: 'España'
    }
  },

  ACTIONS: {
    add: 'Añadir',
    back: 'Atrás',
    continue: 'Continuar',
    download: 'Descargar',
    send: 'Enviar',
    show: 'Mostrar',
    collapse: 'Colapsar',
    expand: 'Expandir',
    start: 'Comenzar',
    reset: 'Reiniciar',
    connect_to: 'Conectarse',
    see_details: 'Ver detalles',
    insert_flag: 'Insertar flag',
    start_machine: 'Arrancar máquina',
    stop_machine: 'Parar máquina',
    report_issue: 'Reportar incidencia',
    lock_machine: 'Bloquear máquina',
    unlock_machine: 'Desbloquear máquina',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    do_again: 'Volver a hacer',
    finish: 'Finalizar',
    show_list: 'Ver listado',
    search: 'Búsqueda',
    refresh: 'Refrescar'
  },

  TOASTS: {
    title_success: '¡Éxito!',
    title_error: '¡Error!'
  },

  TERMS: {
    pills: 'Píldoras',
    completed_pills: 'Píldoras completadas',
    objective: 'Objetivo',
    objectives: 'Objetivos',
    flag: 'Flag',
    labs: 'Labs',
    machines: 'Máquinas',
    pending_machines: 'Máquinas pendientes',
    labs_full: 'Laboratorios',
    careers: 'Carreras técnicas',
    categories_nice: 'Categorías NICE',
    categories_mitre: 'Categorías MITRE',
    techniques_mitre: 'Técnicas MITRE',
    vpn: 'VPN',
    terminal: 'Terminal',
    hints: 'Pistas',
    offline: 'Offline',
    issue: 'Incidencia',
    work_roles: 'Roles de trabajo',
    courses: 'Cursos',
    resources: 'Recursos',
    console: 'Consola',

    NICE: {
      knowledge: 'Conocimiento NICE',
      knowledges: 'Conocimientos NICE',
      ability: 'Capacidad NICE',
      abilities: 'Capacidades NICE',
      skill: 'Habilidad NICE',
      skills: 'Habilidades NICE',
      no_completed: 'Todavía no has completado ninguna categoría NICE',
      all_completed: '¡Has completado todas las categorías NICE!'
    }
  },

  HOME: {
    greeting: 'bienvenido/a de nuevo a CyberAcademy+',
    careers_not_available: 'No hay categorías disponibles'
  },

  PILLS: {
    name: 'Nombre',
    description: 'Descripción',
    status: 'Estado',
    type: 'Tipo',
    difficulty: 'Dificultad',
    points: 'Puntos',
    score: 'Puntuación',
    lessons: 'Lecciones',
    duration: 'Duración',
    recent: 'Píldoras recientes',
    associated: 'Píldoras asociadas',
    no_pills_yet: 'Todavía no has comenzado a resolver píldoras',
    no_recent_pills: 'No hay píldoras recientes',
    no_results: 'No hay píldoras que coincidan con tu criterio',
    list: 'Lista de píldoras',
    list_description: 'Aquí puedes encontar un listado con todas las píldoras de la plataforma',

    DETAIL: {
      work_roles_text: 'Los conocimientos adquiridos al completar esta píldora están asociados con los siguientes roles de trabajo',
      techniques_mitre_text: 'Durante el desarrollo de esta píldora vas a ir usando una serie de tácticas MITRE. Puedes verlas a continuación',
      objectives_extra_text: 'Al completar estas píldoras estarás avanzando en los siguientes objetivos',

      knowledges_text: 'Al completar esta píldora estarás avanzando en los siguientes conocimientos NICE',
      abilities_text: 'Al completar esta píldora estarás avanzando en los siguientes capacidades NICE',
      skills_text: 'Al completar esta píldora estarás avanzando en los siguientes habilidades NICE'
    },

    EXECUTION: {
      progress: 'Progreso de la píldora',
      invalid_answer: 'Respuesta incorrecta',

      no_resources: 'Nada que mostrar',
      no_resources_desc: 'Esta píldora no tiene recursos asociados',
      no_questions_description: 'Parece que esta píldora no tiene preguntas asociadas. Cuando termines las tareas puedes pulsar en "Finalizar"',

      donwload_description: 'Pulsa en el botón para descargar el recurso',

      // Result
      result_title: '¡Píldora finalizada!',
      result_description: 'Esperamos que hayas disfrutado y lo aprendido sea de utilidad.',
      back_to_pill_list: 'Volver al listado de píldoras',

      timer_explanation: 'Tiempo restante. Al finalizar el contador lo recomendable es refrescar la página, ya que algunos recursos podrían caducar',

      time_end_title: 'El tiempo de píldora ha finalizado',
      time_end_text: 'Es posible que algunos recursos no se carguen correctamente. Si tienes algún problema te recomendamos refrescar la página'

    },

    DIFFICULTIES: {
      '0-verylow': 'Muy baja',
      '1-low': 'Baja',
      '2-medium': 'Media',
      '3-high': 'Alta',
      '4-extreme': 'Extrema'
    },

    TYPES: {
      theory: 'Teoría',
      practice: 'Práctica'
    },

    // TODO Verify that those are the values that come from the server
    STATUS: {
      pending: 'Pendiente',
      in_progress: 'En progreso',
      completed: 'Completada'
    },

    ERRORS: {
      start_pill: 'Ha ocurrido un error al arrancar la píldora',
      video_not_suported: 'Vídeo no soportado',
      has_pending_vms: 'Antes de dar por completada la píldora debes completar las máquinas asociadas',
      general: 'Ha ocurrido un error'
    }
  },

  HINTS: {
    unlock: 'Desbloquear pista',
    no_unlocked: 'No hay pistas desbloqueadas',
    machine_has_no_hints: 'La máquina no tiene pistas asociadas',
    unlock_modal_description: '¿Estás seguro de querer desbloquear una nueva pista?'
  },

  LAB_MACHINE: {
    confirm_revert_title: 'Revertir máquina',
    confirm_revert_text: '¿Estás seguro de querer restaurar la máquina a su estado inicial? Esta acción no se puede deshacer',
    confirm_restart_title: 'Reiniciar máquina',
    confirm_restart_text: '¿Estás seguro de querer reiniciar la máquina? Esta acción no se puede deshacer',
    confirm_stop_title: 'Parar máquina',
    confirm_stop_text: '¿Estás seguro de querer parar la máquina? Esta acción no se puede deshacer',
    confirm_unlock_title: 'Desbloquear máquina',
    confirm_unlock_text: '¿Estás seguro de querer desbloquear la máquina? Esta acción no se puede deshacer',

    locked_by_other: 'Bloqueada por otro estudiante',

    ACTIONS: {
      revert: 'Revertir',
      restart: 'Reiniciar',
      stop: 'Parar',
      restart_success: 'La máquina se ha reiniciado con éxito',
      revert_success: 'La máquina se ha revertido con éxito',
      stop_success: 'La máquina se ha parado con éxito',
      UNLOCK_HINT_success: 'Pista desbloqueada con éxito',
      start_machine_success: 'La máquina se ha lanzado con éxito',
      unlock_machine_success: 'La máquina se ha desbloqueado correctamente',

      no_actions_because_blocked_by_other_user: 'No hay acciones disponibles porque la máquina está siendo usada por otro usuario',
      no_actions_because_you_already_have_other_machine: 'No hay acciones disponibles porque ya tienes otra máquina bloqueada'
    },

    STATUS: {
      pending: 'Pendiente',
      in_progress: 'En progreso',
      completed: 'Completada'
    },

    ERRORS: {
      revert: 'Algo ha ocurrido al revertir la máquina. Inténtelo de nuevo más tarde',
      max_reverts_per_student_and_day_matched: 'Ya has realizado todos los reinicios que podías hoy. Inténtalo de nuevo mañana',
      restart: 'Algo ha ocurrido al reiniciar la máquina. Inténtelo de nuevo más tarde',
      start: 'Algo ha ocurrido al iniciar la máquina. Inténtelo de nuevo más tarde',
      stop: 'Algo ha ocurrido al parar la máquina. Inténtelo de nuevo más tarde',
      general: 'Ha ocurrido un error',
      UNLOCK_HINT: 'Algo ha ocurrido al desbloquear la pista. Inténtelo de nuevo más tarde'
    }
  },

  STUDENT_MACHINE: {
    confirm_revert_title: 'Revertir máquina',
    confirm_revert_text: '¿Estás seguro de querer restaurar la máquina a su estado inicial? Esta acción no se puede deshacer',
    confirm_restart_title: 'Reiniciar máquina',
    confirm_restart_text: '¿Estás seguro de querer reiniciar la máquina? Esta acción no se puede deshacer',

    ACTIONS: {
      revert: 'Revertir',
      restart: 'Reiniciar'
    },

    iframe_has_focus: 'La consola tiene focus',
    iframe_doesnt_has_focus: 'La consola no tiene focus',

    ERRORS: {
      no_machine: 'Parece que ha ocurrido algo al cargar tu máquina',
      revert: 'Algo ha ocurrido al revertir la máquina. Inténtelo de nuevo más tarde',
      restart: 'Algo ha ocurrido al reiniciar la máquina. Inténtelo de nuevo más tarde'
    },

    LOADING_STATUS: {
      reverting: 'Revirtiendo...',
      restarting: 'Reiniciando...',
      starting: 'Arrancando máquina...',
      starting_os: 'Arrancando sistema operativo...',
      ready: 'Máquina lista'
    }
  },

  MACHINES: {
    so: 'SO',
    connection_title: 'Datos de conexión',
    no_connection_info: 'No hay información de conexión para esta máquina',
    user: 'Usuario',
    pass: 'Contraseña',
    ip: 'IP',

    pills_associated_description: 'Al completar esta máquina se desbloquerán las píldoras que se muestran a continuación',
    categories_mitre_associated: 'Categorías MITRE asociadas',
    no_categories_mitre_associated: 'No hay categorías MITRE asociadas',
    categories_nice_associated: 'Categorías NICE asociadas',
    no_categories_nice_associated: 'No hay categorías NICE asociadas',
    complete_message: '¡Enhorabuena! Ya has completado esta máquina',

    FLAGS: {
      valid: 'La flag es correcta',
      error: 'Ha ocurrido un error al introducir la flag',
      invalid: 'Flag incorrecta'
    }
  },

  LABS: {
    tab_description: 'Practica lo aprendido',

    name: 'Nombre',
    status: 'Estado',
    recent: 'Laboratorios recientes',
    no_recent_labs: 'No hay labs recientes',
    open_console: 'Abrir terminal de usuario',
    open_console_description: 'Para hacer este lab tienes una máquina preparada a tu disposición',
    lab_complete_progress: 'Completada',
    section_description: 'En esta sección podrás interactuar con distintas máquinas para aplicar los conocimientos aprendidos',

    ERRORS: {
      general: 'Ha ocurrido un error al arrancar la consola'
    }
  },

  CAREERS: {
    summary: 'Resumen de tu carrera técnica'
  },

  CERTIFICATES: {
    name: 'Nombre',
    status: 'Estado',
    add_certification: 'Añadir certificación',
    success_adding: 'Se ha añadido correctamente la certificación',
    error_adding: 'No se ha podido añadir la certificación'
  },

  AUTH: {
    sign_in: 'Iniciar sesión',
    sign_in_m365: 'Iniciar sesión con M365',
    enter_your_username_and_password: 'Introduce tu nombre de usuario y contraseña',
    forgot_password: '¿Has olvidado tu contraseña?',
    forgot_password_description: 'Introduce tu correo electrónico y, en caso de que haya un usuario, te enviaremos un correo electrónico con instrucciones para cambiar tu contraseña',
    email_required: 'El correo electrónico es obligatorio y debe ser válido',
    passwqord_required: 'La contraseña es obligatoria',
    log_out: 'Cerrar sesión',

    sign_up: 'Registrarse',
    sign_up_text: '¿No tienes cuenta? ¡Regístrate!',
    already_registered: '¿Ya tienes cuenta? ¡Inicia sesión!',
    sign_up_error: 'Ha ocurrido un error',

    signup_token: 'Token de registro',

    // ------------- Sign up step 2 ---------------------
    select_certificacions_description: 'A continuación puedes escribir las certificaciones que tengas actualmente. De esta forma, marcaremos como completadas las píldoras asociadas',
    extra_select_certificacions_description: 'En caso de tener alguna certificación de interés que no esté incluida en la lista puedes indicarla a continuación',
    extra_certificacions_placeholder: 'Nombre oficial de la certificación, separado por comas',

    already_know_your_password: '¿Ya conoces tu contraseña? ¡Inicia sesión!',

    forgot_password_success: 'Se ha procesado tu solicitud con éxito. Hemos enviado un correo electrónico con instrucciones a tu email.',
    forgot_password_error: 'Ha ocurrido un error',

    set_password_success: 'Se ha procesado tu solicitud con éxito, ya puedes iniciar sesión usando tu nueva contraseña.',
    set_password_error: 'Ha ocurrido un error',

    confirm_account_description: 'Tu cuenta se ha creado correctamente. Antes de poder iniciar sesión debes activar tu cuenta. Te hemos enviado un correo a la cuenta indicada anteriormente.',
    confirm_account_resend_success: 'Hemos vuelto a enviar el email de confirmación',
    confirm_account_resend_error: 'Ha ocurrido un error al reenviar el email. Vuelve a intentarlo más tarde',

    validated_account_description: 'Tu cuenta ha sido validada correctamente. Ya puedes iniciar sesión en la plataforma.',

    back_to_sign_in: 'Volver al inicio de sesión',

    activate_account_description: '¡Ya falta poco para poder usar tu cuenta! Sólo falta que crees una nueva contraseña y ya podrás acceder a todo el contenido',
    activate_account_success_description: 'Tu cuenta se ha activado correctamente, ya puedes iniciar sesión en la plataforma',

    ERRORS: {
      // ------------ Login ---------------------
      invalid_client_platform_for_student: 'Usuario no válido para esta plataforma de cliente',
      invalid_client_platform: 'Plataforma de cliente inválida',
      invalid_credentials: 'Credenciales inválidas',
      disabled_account: 'Cuenta inactiva',
      general: 'Error iniciando sesión',

      // Sign up
      email_already_used: 'Ya existe un usuario con este email',
      domain_not_included_for_sign_up: 'No es un dominio de email permitido',
      email_domain_blocked: 'No es un dominio de email permitido',
      general_sign_up: 'Error en el registro',
      csrf_invalid: 'El token CSRF es inválido. Refresca la página y vuelve a intentarlo',
      invalid_value: 'Valor inválido',
      license_limit_reached: 'Se ha alcanzado el número máximo de licencias para ese cliente',

      // Activate account
      missing_student_with_token: 'No se ha encontrado un usuario asociado a ese token',
      user_is_not_pending_of_activation: 'El usuario no está pendiente de activación',
      pending_activation: 'El usuario todavía no ha sido verificado',

      // ------------ Reset password ---------------------
      token_already_used: 'Este token ya ha sido usado para activar la cuenta',
      token_missing: 'Para poder activar la cuenta es necesario un token',
      weak_password: 'La contraseña es demasiado débil',

      // ------------ OAuth ---------------------
      domain_not_included: 'Error al iniciar sesión. Es probable que el dominio de tu cuenta de correo no esté incluido entre los aceptados en tu plataforma. Consulta con tu administrador',
      domain_blocked: 'Error al iniciar sesión. Es probable que el dominio de tu cuenta de correo no esté permitido',
      oauth_missing_params: 'Error al iniciar sesión. Faltan parámetros necesarios para continuar con el proceso. Si el problema persiste contacta con un administrador'
    }
  },

  VPN: {
    info: 'Información sobre la VPN',
    info_text_1: 'En algunos escenarios es necesario conectarse vía VPN a la red del laboratorio para poder resolver algunas de las máquinas',
    info_text_2: 'A continuación puedes descargar tu perfil OpenVPN para este laboratorio',
    status: 'Estado',

    download_profile: 'Descargar perfil',
    connection_status: 'Estado de la conexión',
    connected_from: 'Conectado desde',
    virtual_ip: 'IP virtual',
    real_ip: 'IP real',
    device: 'Dispositivo',
    platform: 'Plataforma',

    ERRORS: {
      download: 'Error descargando el perfil VPN'
    }
  },

  PROFILE: {
    last_login: 'Último inicio de sesión',
    my_formation: 'Mi formación',
    info: 'Mi perfil',
    reports: 'Reportes',
    certifications: 'Certificados',

    your_activity: 'Mi actividad',
    your_activity_description: 'Aquí puedes ver un resumen de cuántas píldoras has completado en la última semana. ¡Sigue así!',

    lab_report: 'Reporte de labs',
    objective_report: 'Reporte de objetivos',
    career_report: 'Reporte de carrera',

    upload_profile_pic: 'Subir imagen de perfil',
    PROGRESS: {
      your_progress: 'Tu progreso',
      your_progress_description: 'En esta sección podrás ver cuánto has avanzado en las distintas categorías'
    },

    CERTIFICATIONS: {
      my_certifications: 'Mis certificaciones',
      my_certifications_desc: 'Estás son las certificaciones que tienes actualmente, ¡enhorabuena!',
      available_certificates: 'Certificaciones disponibles',
      available_certificates_desc: 'Estas son las certificaciones que existen actualmente en la plataforma. Si tienes alguna de ellas ponte en contacto con administración para que la añadan a tu perfil'
    },

    FORM: {
      username: 'Nombre de usuario',
      country: 'País',
      language: 'Idioma',

      ERRORS: {
        username: 'Debe de tener al menos tres caracteres',
        error_uploading_pic: 'Error subiendo foto'
      }
    }
  },

  TABLE: {
    first: 'Primera',
    last: 'Última',
    next: 'Siguiente',
    previous: 'Anterior',

    items_per_page: 'Elementos por página',
    items_total: 'Elementos totales'
  },

  FORMS: {
    name: 'Nombre',
    email: 'Email',
    password: 'Contraseña',
    confirm_password: 'Confirmar contraseña',

    length_requirement: 'Debe tener al menos {n} caracteres',
    max_length_requirement: 'Debe tener menos de {n} caracteres',
    password_requirements: 'La contraseña debe de contener al menos una letra minúscula, una mayúscula, un número y un carácter especial',
    same_password: 'Las contraseñas deben coincidir',
    required: 'Requerido',

    update_success: 'Datos actualizados con éxito',

    ERRORS: {
      name_length: 'Debe tener al menos tres caracteres',
      generic: 'Ha ocurrido un error al enviar el formulario'
    }
  },

  FOOTER: {
    content: '©{year} CYBERACADEMY+. TODOS LOS DERECHOS RESERVADOS.'
  },

  RRSS: {
    twitter: 'https://twitter.com/ihacklabs',
    facebook: 'https://www.facebook.com/ihacklabs',
    contact: 'support@ihacklabs.com'
  },

  LEGAL: {
    privacidad: 'Privacidad',
    legal: 'Legal',
    contacto: 'Contacto',

    PRIVACY: {
      title: 'Política de privacidad'
    },

    TERMS_AND_CONDITIONS: {
      title: 'Términos y condiciones'
    },

    USE_POLICY: {
      title: 'Condiciones de uso'
    },

    COOKIE_POLICY: {
      title: 'Política de cookies'
    }
  },

  PAGES: {
    dashboard: 'Dashboard',
    home: 'Inicio',
    objectives: 'Objetivos',
    objectiveDetail: 'Detalle de objetivo',
    categories: 'Academia',
    careers: 'Carreras técnicas',
    careerDetail: 'Detalle de carrera técnicas',
    courses: 'Cursos',
    courseDetail: 'Detalle de curso',
    workRoleDetail: 'Detalle de Work Role',
    labs: 'Labs',
    labDetail: 'Detalle de lab',
    machineDetail: 'Detalle de máquina',
    pills: 'Listado de píldoras',
    pillDetail: 'Detalle de píldora',
    pillExecution: 'Ejecución de píldora',
    pillExecutionResult: 'Resultado de píldora',
    terminal: 'Consola de jugador',
    faqs: 'FAQs',
    profileHome: 'Perfil',
    profileInfo: 'Información del usuario',
    profileReports: 'Reportes',
    profileVpn: 'VPN',
    profileCertifications: 'Certificaciones del usuario',
    login: 'Inicio de sesión',
    register: 'Registro',
    confirmAccount: 'Confirmar cuenta',
    confirmAccountEndpoint: 'Confirmar cuenta',
    forgotPassword: 'Recuperar contraseña',
    forgotPasswordSuccess: 'Recuperar contraseña',
    setPassword: 'Cambiar contraseña',
    setPasswordSuccess: 'Cambiar contraseña',
    privacy: 'Privacidad',
    termsAndConditions: 'Términos y condiciones',
    error: 'Error',
    oauthCallback: 'Oauth',
    mitreByUser: 'Categorías MITRE',
    niceByUser: 'Categorías NICE',
    activateAccountEndpoint: 'Activar cuenta',
    activateAccountSuccess: 'Cuenta activada',

    DESCRIPTIONS: {
      mitre_description: 'Aquí puedes ver las categorías MITRE que has completado actualmente',
      nice_description: 'Aquí puedes ver las categorías NICE que has completado actualmente'
    }
  },

  ISSUES: {
    placeholder: 'Describe la incidencia...',
    success: 'Incidencia reportada con éxito',
    error: 'Ha ocurrido un error al enviar la incidencia. Inténtelo de nuevo más tarde'
  },

  ERRORS: {
    // ------------ General ---------------------
    general: 'Algo ha ocurrido',
    forbidden: 'Recurso no accesible',
    not_found: 'Recurso no encontrado',
    video_not_suported: 'Vídeo no soportado',

    cannot_open_new_tab: 'No se ha podido abrir una nueva pestaña'
  },

  PDFS: {
    CERTIFICATION: {
      certificate: 'Certificado',
      generate_certificate: 'Generar certificado',
      given_to: 'Otorgado a',
      total_duration: 'Duración total del curso',
      course_director: 'Director del curso',
      date_finish: 'Fecha de finalización'
    }
  }
}
