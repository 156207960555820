<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import URLS from '@/config/urls'

export default {
  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
      getGeneralConfig: 'config/getGeneralConfig',
      getVpnConnectionStatus: 'config/getVpnConnectionStatus'
    }),
    addCustomCss () {
      var element = document.createElement('link')
      element.setAttribute('rel', 'stylesheet')
      element.setAttribute('type', 'text/css')
      element.setAttribute('href', URLS.API.STATIC.CUSTOM_CSS)
      document.getElementsByTagName('head')[0].appendChild(element)
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      canSeeVpn: 'config/canSeeVpn'
    })
  },
  created () {
    // We set this at the @/config/urls.js file
    if (this.clientSubdomain !== '') {
      this.addCustomCss()
    }

    if (this.isAuthenticated) {
      this.getUser()

      this.getGeneralConfig()
        .then(_ => {
          if (this.canSeeVpn) {
            this.getVpnConnectionStatus()

            setInterval(_ => {
              this.getVpnConnectionStatus()
            }, 15 * 1000)
          }
        })
    }
  }
}
</script>

<style lang="scss">

// @ is an alias to /src
@import "@/assets/scss/base.scss";

#app {
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: $primary;
    }
  }
}
</style>
