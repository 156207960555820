export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function pushBreadcrumb (state, step) {
  state.breadcrumbs.push(step)
}

export function popBreadcrumb (state) {
  state.breadcrumbs.pop()
}

export function clearBreadcrumbs (state) {
  state.breadcrumbs = []
}
