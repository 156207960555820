export const PUBLIC_PAGES = [
  'login',
  'register',
  'confirmAccount',
  'termsAndConditions',
  'privacy',
  'confirmAccountEndpoint',
  'forgotPassword',
  'forgotPasswordSuccess',
  'setPassword',
  'setPasswordSuccess',
  'oauthCallback',
  'activateAccountEndpoint',
  'activateAccountSuccess'
]

export const DIFFICULTIES = {
  VERY_LOW: '0-verylow',
  LOW: '1-low',
  MEDIUM: '2-medium',
  HARD: '3-high',
  EXTREME: '4-extreme'
}

export const PILL_TYPES = {
  THEORY: 'theory',
  PRACTICE: 'practice'
}

export const PILL_STATUS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'completed'
}

export const LAB_MACHINE_STATUS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'completed'
}

export const STUDENT_MACHINE_ACTIONS = {
  REVERT: 'revert',
  RESTART: 'restart'
}

export const STUDENT_MACHINE_START_STATUS = {
  PENDING: 'pending',
  RUNNING: 'running'
}

export const STUDENT_MACHINE_BOOT_STATUS = {
  PENDING: false,
  IN_PROGRESS: 'initializing',
  RUNNING: 'ok'
}

export const LAB_MACHINE_ACTIONS = {
  REVERT: 'revert',
  RESTART: 'restart',
  START: 'start'
}

export const LAB_MACHINE_TYPE = {
  AWS: 'aws',
  VMWARE: 'vmware'
}
