export const locale = {
  hello: 'Hello world',

  GENERAL: {
    titulo_bienvenida: '¡Bienvenido a academia!',
    hello: 'Hi',
    quick_actions: 'Quick actions',
    go_to_profile: 'Go to profile',
    information: 'Information',
    second: 'second',
    seconds: 'seconds',
    minute: 'minute',
    minutes: 'minutes',
    hour: 'hour',
    hours: 'hours',
    points: 'points',
    select_one: 'Select one',
    faqs: 'FAQs',
    faqs_full: 'Frequently asked questions',
    no_results: 'No results',
    no_results_sub: 'There seems to be no item available',
    congratulations: 'Congratulations!',
    loading: 'Loading',
    back_to_home: 'Back to home',
    title_error: 'Error!',
    actions: 'Actions',
    home: 'Home',
    academy: 'Academy',
    see_more: 'Show more',
    oops: 'Oops',
    show_more: 'Show more',
    show_less: 'Show less',
    no_selection: 'No selection',
    completed_fem: 'Completed',
    pending: 'Pending',

    only_showing_available_pills: 'Only showing currently available pills',
    currentBlockedMachine: 'Currently used machine',

    LANGS: {
      Spanish: 'Spanish',
      English: 'English',
      German: 'German'
    },
    COUNTRIES: {
      Spain: 'Spain'
    }
  },

  ACTIONS: {
    add: 'Add',
    back: 'Back',
    continue: 'Continue',
    download: 'Download',
    send: 'Send',
    show: 'Show',
    collapse: 'Collapse',
    expand: 'Expand',
    start: 'Start',
    reset: 'Reset',
    connect_to: 'Connect to',
    see_details: 'See details',
    insert_flag: 'Insert flag',
    start_machine: 'Start machine',
    stop_machine: 'Stop machine',
    lock_machine: 'Lock machine',
    unlock_machine: 'Unlock machine',
    report_issue: 'Report issue',
    cancel: 'Cancel',
    accept: 'Accept',
    do_again: 'Do again',
    finish: 'Finish',
    show_list: 'Show list',
    search: 'Search',
    refresh: 'Refresh'
  },

  TOASTS: {
    title_success: 'Success!',
    title_error: 'Error!'
  },

  TERMS: {
    pills: 'Pills',
    completed_pills: 'Completed pills',
    objective: 'Objective',
    objectives: 'Objectives',
    flag: 'Flag',
    labs: 'Labs',
    machines: 'Machines',
    pending_machines: 'Pending machines',
    labs_full: 'Laboratories',
    careers: 'Technical careers',
    categories_nice: 'NICE categories',
    categories_mitre: 'MITRE categories',
    techniques_mitre: 'MITRE techniques',
    vpn: 'VPN',
    terminal: 'Terminal',
    hints: 'Hints',
    offline: 'Offline',
    issue: 'Issue',
    work_roles: 'Work roles',
    courses: 'Courses',
    resources: 'Resources',
    console: 'Console',

    NICE: {
      knowledge: 'NICE knowledge',
      knowledges: 'NICE knowledges',
      ability: 'NICE ability',
      abilities: 'NICE abilities',
      skill: 'NICE skill',
      skills: 'NICE skills',
      no_completed: 'You have not completed any NICE categories yet',
      all_completed: 'You have completed all the NICE categories!'
    }
  },

  HOME: {
    greeting: 'welcome back to CyberAcademy+',
    careers_not_available: 'No categories available'
  },

  PILLS: {
    name: 'Name',
    description: 'Description',
    status: 'Status',
    type: 'Type',
    difficulty: 'Difficulty',
    points: 'Points',
    score: 'Score',
    lessons: 'Lessons',
    duration: 'Duration',
    recent: 'Recent pills',
    associated: 'Associated pills',
    no_pills_yet: 'You haven\'t started solving pills yet',
    no_recent_pills: 'No recent pills',
    no_results: 'There are no pills that match your criteria',
    list: 'Pill list',
    list_description: 'Here you can find a list with all the pills on the platform',

    DETAIL: {
      work_roles_text: 'The knowledge gained from completing this pill is associated with the following job roles',
      techniques_mitre_text: 'During the development of this pill you will be using a series of MITER tactics. You can see them below',
      objectives_extra_text: 'By completing these pills you will be progressing in the following objectives',

      knowledges_text: 'By completing this pill you will be advancing in the following NICE knowledge',
      abilities_text: 'By completing this pill you will be advancing in the following NICE abilities',
      skills_text: 'By completing this pill you will be advancing in the following NICE skills'
    },

    EXECUTION: {
      progress: 'Pill progress',
      invalid_answer: 'Wrong answer',

      no_resources: 'Nothing to show',
      no_resources_desc: 'This pill has no associated resources',
      no_questions_description: 'It seems that this pill has no questions associated with it. When you finish the tasks you can click on "Finish"',

      donwload_description: 'Click on the button to download the resource',

      // Result
      result_title: 'Pill finished!',
      result_description: 'We hope you have enjoyed and what you learned is useful.',
      back_to_pill_list: 'Back to the list of pills',

      timer_explanation: 'Time remaining. At the end of the counter, it is advisable to refresh the page, since some resources could expire ',

      time_end_title: 'Pill time has expired',
      time_end_text: 'Some resources may not load correctly. If you have any problems we recommend you refresh the page'
    },

    DIFFICULTIES: {
      '0-verylow': 'Very low',
      '1-low': 'Low',
      '2-medium': 'Medium',
      '3-high': 'High',
      '4-extreme': 'Extreme'
    },

    TYPES: {
      theory: 'Theory',
      practice: 'Practice'
    },

    // TODO Verify that those are the values that come from the server
    STATUS: {
      pending: 'Pending',
      in_progress: 'In progress',
      completed: 'Completed'
    },

    ERRORS: {
      start_pill: 'An error occurred while starting the pill',
      video_not_suported: 'Video not supported',
      has_pending_vms: 'Before completing the pill, you must complete the associated machines',
      general: 'An error has occurred'
    }
  },

  HINTS: {
    unlock: 'Unlock hint',
    no_unlocked: 'No hints unlocked',
    machine_has_no_hints: 'The machine has no associated hints',
    unlock_modal_description: 'Are you sure you want to unlock a new hint?'
  },

  LAB_MACHINE: {
    confirm_revert_title: 'Revert machine',
    confirm_revert_text: 'Are you sure you want to restore the machine to its initial state? This action can not be undone',
    confirm_restart_title: 'Reboot machine',
    confirm_restart_text: 'Are you sure you want to restart the machine? This action can not be undone',
    confirm_stop_title: 'Stop machine',
    confirm_stop_text: 'Are you sure you want to stop the machine? This action can not be undone',
    confirm_unlock_title: 'Unlock machine',
    confirm_unlock_text: 'Are you sure you want to unlock the machine? This action can not be undone',

    locked_by_other: 'Locked by other student',

    ACTIONS: {
      revert: 'Revert',
      restart: 'Reboot',
      stop: 'Machine',
      restart_success: 'The machine has been successfully rebooted',
      revert_success: 'The machine has been successfully reverted',
      stop_success: 'The machine has been successfully stopped',
      UNLOCK_HINT_success: 'Hint successfully unlocked',
      start_machine_success: 'The machine has been successfully started',
      unlock_machine_success: 'The machine has been unlocked successfully',

      no_actions_because_blocked_by_other_user: 'No actions available because the machine is being used by another user',
      no_actions_because_you_already_have_other_machine: 'There are no actions available because you already have another machine locked'
    },

    STATUS: {
      pending: 'Pending',
      in_progress: 'In progress',
      completed: 'Completed'
    },

    ERRORS: {
      revert: 'Something has happened while reverting the machine. Try it again later',
      max_reverts_per_student_and_day_matched: 'You\'ve already done all the resets you could today. Try again tomorrow',
      restart: 'Something has happened while restarting the machine. Try it again later',
      start: 'Something has happened when starting the machine. Try it again later',
      stop: 'Something has happened when stopping the machine. Try it again later',
      general: 'An error has occurred',
      UNLOCK_HINT: 'Something happened while unlocking the hint. Try it again later'
    }
  },

  STUDENT_MACHINE: {
    confirm_revert_title: 'Revert machine',
    confirm_revert_text: 'Are you sure you want to restore the machine to its initial state? This action can not be undone',
    confirm_restart_title: 'Reboot machine',
    confirm_restart_text: 'Are you sure you want to restart the machine? This action can not be undone',

    ACTIONS: {
      revert: 'Revert',
      restart: 'Reboot'
    },

    iframe_has_focus: 'The console has focus',
    iframe_doesnt_has_focus: 'The console has no focus',

    ERRORS: {
      no_machine: 'It seems that something has happened while loading your machine',
      revert: 'Something has happened while reversing the machine. Try it again later',
      restart: 'Something has happened while restarting the machine. Try it again later'
    },

    LOADING_STATUS: {
      reverting: 'Reverting...',
      restarting: 'Rebooting...',
      starting: 'Starting machine...',
      starting_os: 'Booting operating system...',
      ready: 'Machine ready'
    }
  },

  MACHINES: {
    so: 'OS',
    connection_title: 'Connection info',
    no_connection_info: 'There is no connection information for this machine',
    user: 'User',
    pass: 'Password',
    ip: 'IP',

    pills_associated_description: 'Completing this machine will unlock the pills shown below',
    categories_mitre_associated: 'Associated MITER categories',
    no_categories_mitre_associated: 'There are no associated MITER categories',
    categories_nice_associated: 'Associated NICE categories',
    no_categories_nice_associated: 'There are no NICE categories associated',
    complete_message: 'Congratulations! You have already completed this machine ',

    FLAGS: {
      valid: 'The flag is correct',
      error: 'Something has happened when inserting the flag',
      invalid: 'Wrong flag'
    }
  },

  LABS: {
    tab_description: 'Practice what you have learned',

    name: 'Name',
    status: 'Status',
    recent: 'Recent labs',
    no_recent_labs: 'There are no recent labs',
    open_console: 'Open user terminal',
    open_console_description: 'To do this lab you have a machine at your disposal',
    lab_complete_progress: 'Completed',
    section_description: 'In this section you will be able to interact with different machines to apply the knowledge learned',

    ERRORS: {
      general: 'An error occurred while starting the console'
    }
  },

  CAREERS: {
    summary: 'Summary of your technical career '
  },

  CERTIFICATES: {
    name: 'Name',
    status: 'Status',
    add_certification: 'Add certification',
    success_adding: 'The certification has been added successfully',
    error_adding: 'Unable to add certification'
  },

  AUTH: {
    sign_in: 'Sign in',
    sign_in_m365: 'Sign in with M365',
    enter_your_username_and_password: 'Enter your username and password',
    forgot_password: 'Forgot your password?',
    forgot_password_description: 'Enter your email and, in case there is a user, we will send you an email with instructions to change your password',
    email_required: 'Email is required and must be valid',
    passwqord_required: 'The password is required',
    log_out: 'Close session',

    sign_up: 'Register',
    sign_up_text: 'Don\'t have an account? Sign up!',
    already_registered: 'Do you already have an account? Log in!',
    sign_up_error: 'An error has occurred',

    signup_token: 'Signup token',

    // ------------- Sign up step 2 ---------------------
    select_certificacions_description: 'Next you can write the certifications you currently have. In this way, we will mark the associated pills as completed',
    extra_select_certificacions_description: 'In case you have a certification of interest that is not included in the list, you can indicate it below',
    extra_certificacions_placeholder: 'Official name of the certification, separated by commas',

    already_know_your_password: 'Do you already know your password? Log in!',

    forgot_password_success: 'Your request has been processed successfully. We have sent an email with instructions to your email. ',
    forgot_password_error: 'An error has occurred',

    set_password_success: 'Your request has been processed successfully, you can now log in using your new password.',
    set_password_error: 'An error has occurred',

    confirm_account_description: 'Your account has been created successfully. Before you can log in, you must activate your account. We have sent you an email to the account indicated above. ',
    confirm_account_resend_success: 'We have resent the confirmation email',
    confirm_account_resend_error: 'An error occurred while resending the email. Try again later',

    validated_account_description: 'Your account has been successfully validated. You can now log into the platform. ',

    back_to_sign_in: 'Return to login',

    activate_account_description: '¡Ya falta poco para poder usar tu cuenta! Sólo falta que crees una nueva contraseña y ya podrás acceder a todo el contenido',
    activate_account_success_description: 'Your account has been successfully activated, you can now log into the platform',

    ERRORS: {
      // ------------ Login ---------------------
      invalid_client_platform_for_student: 'Invalid user for this client platform',
      invalid_client_platform: 'Invalid client platform',
      invalid_credentials: 'Invalid credentials',
      general: 'Error logging in',
      csrf_invalid: 'The CSRF token is invalid. Refresh the page and try again',
      disabled_account: 'Account is disabled',

      // Sign up
      email_already_used: 'A user with this email already exists',
      domain_not_included_for_sign_up: 'Not an email domain allowed',
      email_domain_blocked: 'Not an email domain allowed',
      general_sign_up: 'Registration failed',
      invalid_value: 'Invalid value',
      license_limit_reached: 'The maximum number of licenses for that client has been reached',

      // Activate account
      missing_student_with_token: 'A user associated with that token was not found',
      user_is_not_pending_of_activation: 'User is not pending of activation',
      pending_activation: 'User has not yet been verified',

      // ------------ Reset password ---------------------
      token_already_used: 'This token has already been used to activate the account',
      token_missing: 'A token is required to activate the account',
      weak_password: 'The password is too weak',

      // ------------ OAuth ---------------------
      domain_not_included: 'Login failed. It is likely that the domain of your email account is not included among those accepted on your platform. Check with your administrator ',
      domain_blocked: 'Failed to login. It is likely that the domain of your email account is not allowed ',
      oauth_missing_params: 'Failed to login. Parameters required to continue the process are missing. If the problem persists contact an administrator '
    }
  },

  VPN: {
    info: 'Information about the VPN',
    info_text_1: 'In some scenarios it is necessary to connect via VPN to the laboratory network to be able to solve some of the machines',
    info_text_2: 'Below you can download your OpenVPN profile for this lab',
    status: 'Status',

    download_profile: 'Download profile',
    connection_status: 'Connection status',
    connected_from: 'Connected since',
    virtual_ip: 'virtual IP',
    real_ip: 'real IP',
    device: 'Device',
    platform: 'Platform',

    ERRORS: {
      download: 'Error downloading VPN profile'
    }
  },

  PROFILE: {
    last_login: 'Last login',
    my_formation: 'My training',
    info: 'My profile',
    reports: 'Reports',
    certifications: 'Certificates',

    your_activity: 'My activity',
    your_activity_description: 'Here you can see a summary of how many pills you have completed in the last week. Keep it up!',

    lab_report: 'Labs report',
    objective_report: 'Objectives report',
    career_report: 'Career report',

    upload_profile_pic: 'Upload profile image',
    PROGRESS: {
      your_progress: 'Your progress',
      your_progress_description: 'In this section you will see how much you have advanced in the different categories'
    },

    CERTIFICATIONS: {
      my_certifications: 'My certifications',
      my_certifications_desc: 'These are the certifications you currently have, congratulations!',
      available_certificates: 'Available certifications',
      available_certificates_desc: 'These are the certifications that currently exist on the platform. If you have any of them, contact the administration so that they can add it to your profile '
    },

    FORM: {
      username: 'Username',
      country: 'Country',
      language: 'Language',

      ERRORS: {
        username: 'Must be at least three characters long',
        error_uploading_pic: 'Error uploading photo'
      }
    }
  },

  TABLE: {
    first: 'First',
    last: 'Last',
    next: 'Next',
    previous: 'Previous',

    items_per_page: 'Items per page',
    items_total: 'Total items'
  },

  FORMS: {
    name: 'Name',
    email: 'Email',
    password: 'Password',
    confirm_password: 'Confirm password',

    length_requirement: 'Must be at least {n} characters',
    max_length_requirement: 'Must be less than {n} characters',
    password_requirements: 'The password must contain at least one lowercase letter, one uppercase letter, one number and one special character',
    same_password: 'Passwords must match',
    required: 'Required',

    update_success: 'Data updated successfully',

    ERRORS: {
      name_length: 'Must have at least three characters',
      generic: 'An error occurred while submitting the form'
    }
  },

  FOOTER: {
    content: '©{year} CYBERACADEMY+. ALL RIGHTS RESERVED.'
  },

  RRSS: {
    twitter: 'https://twitter.com/ihacklabs',
    facebook: 'https://www.facebook.com/ihacklabs',
    contact: 'support@ihacklabs.com'
  },

  LEGAL: {
    privacy: 'Privacy',
    legal: 'Legal',
    contact: 'Contact',

    PRIVACY: {
      title: 'Privacy Policy'
    },

    TERMS_AND_CONDITIONS: {
      title: 'Terms and conditions'
    },

    USE_POLICY: {
      title: 'Terms of use'
    },

    COOKIE_POLICY: {
      title: 'Cookies policy'
    }
  },

  PAGES: {
    dashboard: 'Dashboard',
    home: 'Home',
    objectives: 'Objectives',
    objectiveDetail: 'Objective detail',
    categories: 'Academy',
    careers: 'Technical careers',
    careerDetail: 'Technical career detail',
    courses: 'Courses',
    courseDetail: 'Course detail',
    workRoleDetail: 'Work Role Detail',
    labs: 'Labs',
    labDetail: 'Lab Detail',
    machineDetail: 'Machine detail',
    pills: 'List of pills',
    pillDetail: 'Pill detail',
    pillExecution: 'Pill Execution',
    pillExecutionResult: 'Pill result',
    terminal: 'Player console',
    faqs: 'FAQs',
    profileHome: 'Profile',
    profileInfo: 'User information',
    profileReports: 'Reports',
    profileVpn: 'VPN',
    profileCertifications: 'User certifications',
    login: 'Login',
    register: 'Register',
    confirmAccount: 'Confirm account',
    confirmAccountEndpoint: 'Confirm account',
    forgotPassword: 'Recover password',
    forgotPasswordSuccess: 'Recover password',
    setPassword: 'Change password',
    setPasswordSuccess: 'Change password',
    privacy: 'Privacy',
    termsAndConditions: 'Terms and conditions',
    error: 'Error',
    oauthCallback: 'Oauth',
    miterByUser: 'MITER Categories',
    niceByUser: 'NICE Categories',
    activateAccountEndpoint: 'Activate account',
    activateAccountSuccess: 'Account activated',

    DESCRIPTIONS: {
      mitre_description: 'Here you can see the MITRE categories you have currently completed',
      nice_description: 'Here you can see the NICE categories you have currently completed'
    }
  },

  ISSUES: {
    placeholder: 'Describe the issue ...',
    success: 'Issue reported successfully',
    error: 'An error occurred while submitting the issue. Try it again later'
  },

  ERRORS: {
    // ------------ General ---------------------
    general: 'Something has happened',
    forbidden: 'Resource not accessible',
    not_found: 'Resource not found',
    video_not_suported: 'Video not supported',

    cannot_open_new_tab: 'Could not open a new tab'
  },

  PDFS: {
    CERTIFICATION: {
      certificate: 'Certificate',
      generate_certificate: 'Generate certificate',
      given_to: 'Awarded to',
      total_duration: 'Total duration of the course',
      course_director: 'Course Director',
      date_finish: 'Finish date'
    }
  }
}
