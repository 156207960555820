
const langObject = {
  en: {
    lang: 'en',
    name: 'English',
    flag: process.env.BASE_URL + 'media/svg/flags/226-united-states.svg'
  },
  es: {
    lang: 'es',
    name: 'Spanish',
    flag: process.env.BASE_URL + 'media/svg/flags/128-spain.svg'
  },
  de: {
    lang: 'de',
    name: 'German',
    flag: process.env.BASE_URL + 'media/svg/flags/162-germany.svg'
  }
}

const availableLangs = process.env.VUE_APP_AVAILABLE_LANGS || 'es,en'
const availableLangsList = availableLangs.split(',')
const languages = availableLangsList.map(i => langObject[i])

const i18nService = {
  defaultLanguage: availableLangsList[0],
  languages,

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage (lang) {
    localStorage.setItem('language', lang)
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage () {
    return localStorage.getItem('language') || this.defaultLanguage
  }
}

export default i18nService
