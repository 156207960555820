import { getter } from '@/services/store/utils'
import URLS from '@/config/urls'

export function getObjectivesFirstLevel (context) {
  return getter(context, URLS.API.OBJECTIVES.GET_OBJECTIVES_FIRST_LEVEL, 'setObjectivesFirstLevel')
}

export function getObjectives (context) {
  return getter(context, URLS.API.OBJECTIVES.GET_OBJECTIVES, 'setObjectives')
}

export function getObjectiveDetail (context, id) {
  return getter(context, URLS.API.OBJECTIVES.GET_OBJECTIVE_DETAIL(id), 'setObjectiveDetail')
}
