import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './services/store/index'

import i18n from '@/plugins/vue-i18n'
import apexcharts from '@/plugins/apexcharts'

import ApiService from '@/services/api.service'

import { PUBLIC_PAGES } from '@/config/constants'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Loading overlay
import Loading from '@/components/utils/LoadingOverlay'
import Card from '@/components/utils/cards/Card'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faServer, faFilePdf, faPlay, faFile, faFileArchive, faQuestionCircle, faFlask, faBullseye, faGraduationCap, faBriefcase, faChartPie, faList, faExpandAlt, faCompressAlt, faBookOpen, faCamera, faCrosshairs, faHistory, faUndo, faHome, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitter, faWindows } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Multiselect from 'vue-multiselect'

// register globally
Vue.component('multiselect', Multiselect)
// Top navigation
library.add(faChartPie)
library.add(faGraduationCap)
library.add(faBriefcase)
library.add(faBullseye)
library.add(faFlask)
library.add(faList)
library.add(faHome)
// --------

// Pill execution
library.add(faCompressAlt)
library.add(faExpandAlt)
library.add(faServer)
library.add(faFile)
library.add(faFilePdf)
library.add(faPlay)
library.add(faFileArchive)
library.add(faBookOpen)
// --------

library.add(faQuestionCircle)
library.add(faEnvelope)

library.add(faCamera)

library.add(faCrosshairs)

library.add(faFacebookSquare)
library.add(faTwitter)
library.add(faWindows)

library.add(faHistory)
library.add(faUndo)

library.add(faChevronDown)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('loading', Loading)
Vue.component('Card', Card)

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

ApiService.init()

// if (process.env.NODE_ENV === 'development') {
// MockService.init()
// }

Vue.config.productionTip = false

// var currentFlow = ''

// const getPathFlow = function (route) {
//   var newFlow = ''

//   if (['categories', 'categoryDetail'].indexOf(route.name) !== -1) {
//     newFlow = 'categories'
//   }

//   if (route.name === 'labs') {
//     newFlow = 'labs'
//   }

//   return newFlow
// }

const DEFAULT_TITLE = 'CyberAcademy+'
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if (typeof to.meta.title === 'undefined') {
      document.title = i18n.t(`PAGES.${to.name}`)
    } else {
      document.title = typeof to.meta.title === 'function' ? to.meta.title(to) : (to.meta.title || DEFAULT_TITLE)
    }
  })
})

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch('auth/verifyAuth')])
  // (new Promise(resolve => resolve()))
    .then(function () {
      if (PUBLIC_PAGES.indexOf(to.name) !== -1 || store.getters['auth/isAuthenticated']) {
        next()
      } else {
        if (from.name !== 'login') {
          next({ name: 'login' })
        }
      }
    })

    .then(_ => {
      if (!store.getters['config/canSeeCareers'] && (to.name === 'careers' || to.name === 'careerDetail')) {
        next({ name: 'error', params: { error: 404 } })
      } else if (store.getters['config/canSeeUserConsole'] != null && !store.getters['config/canSeeUserConsole'] && to.name === 'terminal') {
        next({ name: 'error', params: { error: 403 } })
      } else {
        next()
      }
    })

    .catch(err => {
      console.log(err)
      next({ name: 'login' })
    })

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

Vue.filter('striphtml', function (value) {
  var div = document.createElement('div')
  div.innerHTML = value
  var text = div.textContent || div.innerText || ''
  return text
})

new Vue({
  router,
  store,
  i18n,
  apexcharts,
  render: h => h(App)
}).$mount('#app')
