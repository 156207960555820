import i18n from '@/plugins/vue-i18n'

import ApiService from '@/services/api.service'
import JwtService from '@/services/jwt.service'
import store from '@/services/store/index'

import URLS from '@/config/urls'

export function login (context, credentials) {
  context.commit('startLoading')

  return ApiService.post(URLS.API.AUTH.LOGIN_URL, credentials)
    .then(response => {
      if (typeof response.data.token === 'undefined') throw response

      context.commit('setAuth', response.data)
      context.commit('endLoading')

      ApiService.setHeader()
      context.dispatch('getUser')
    })
    .catch((err) => {
      var error = []
      const response = err?.response
      if (typeof response !== 'undefined' && response.status === 401) {
        switch (response.data.message) {
          case 'security.auth.invalid_client_platform_for_student': {
            error.push(i18n.t('AUTH.ERRORS.invalid_client_platform_for_student'))
            break
          }
          case 'auth.errors.invalid_client_platform': {
            error.push(i18n.t('AUTH.ERRORS.invalid_client_platform'))
            break
          }
          case 'Invalid credentials.': {
            error.push(i18n.t('AUTH.ERRORS.invalid_credentials'))
            break
          }
          case 'Account is disabled.': {
            error.push(i18n.t('AUTH.ERRORS.disabled_account'))
            break
          }
          default: {
            error.push(i18n.t('AUTH.ERRORS.general'))
          }
        }
      } else {
        error.push(i18n.t('AUTH.ERRORS.general'))
      }
      context.commit('setError', error)
      context.commit('endLoading')
    })
}

export function logout (context) {
  return ApiService.query(URLS.API.AUTH.LOGOUT)
    .then(_ => { context.commit('purgeAuth') })
    .catch(_ => { context.commit('purgeAuth') })
  // TODO Should purge ALL stores data
}

const REFRESH_TOKEN_PERIOD = process.env.VUE_APP_TOKEN_REFRESH_INTERVAL * 60 * 1000

export async function verifyAuth (context) {
  const currentTimeStamp = (new Date().getTime())
  const loginTimestamp = JwtService.getTimeStamp() || currentTimeStamp

  const shouldRefreshToken = currentTimeStamp - loginTimestamp > REFRESH_TOKEN_PERIOD

  if (shouldRefreshToken && !context.getters.isRefreshing) {
    await context.dispatch('refreshToken')
  }
}

export function refreshToken (context) {
  context.commit('startRefreshingToken')
  return ApiService.post(URLS.API.AUTH.REFRESH_TOKEN, { refresh_token: JwtService.getRefreshToken() })
    .then(({ data }) => {
      context.commit('setAuth', data)
      context.commit('endLoading')

      ApiService.setHeader()
      context.dispatch('getUser')
    })
    .finally(_ => { context.commit('endRefreshingToken') })
}

export function getOnlyUser (context) {
  return ApiService.query(URLS.API.USER.GET_STUDENT)
}

export function getUser (context) {
  return ApiService.query(URLS.API.USER.GET_STUDENT)
    .then(data => {
      context.commit('setUser', data.data)
    })
    .then(_ => {
      return ApiService.getImg(URLS.API.USER.GET_STUDENT_PROFILE_PIC)
    })
    .then(data => {
      context.commit('setUserProfilePic', data.data)
    })
    .catch(_ => {
      // console.log(error)
    })
}

export function updateUserProfilePic (context, img) {
  context.commit('startLoading')

  const data = new FormData()
  data.append('image', img)

  return ApiService.postImg(
    URLS.API.USER.SET_STUDENT_PROFILE_PIC,
    data
  )
    .then(response => {
      if (response.status !== 200 || response.data.status === 'error') throw response
      return ApiService.getImg(URLS.API.USER.GET_STUDENT_PROFILE_PIC)
    })
    .then(data => {
      context.commit('setUserProfilePic', data.data)
      context.commit('endLoading')
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function signUp (context, params) {
  context.commit('startLoading')

  const headers = {
    'Content-Type': 'application/json',
    'X-Request-Intent': store.getters['auth/getCsrfToken']
  }
  return ApiService.postWithConfig(URLS.API.AUTH.SIGN_UP, params, { headers: headers, withCredentials: true })
    .then(response => {
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function forgotPassword (context, params) {
  context.commit('startLoading')

  return ApiService.post(URLS.API.AUTH.FORGOT_PASSWORD, params)
    .then(response => {
      if (response.status !== 200 || response.data.status !== 'ok') throw response
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function setPassword (context, params) {
  context.commit('startLoading')

  return ApiService.post(URLS.API.AUTH.SET_PASSWORD, params)
    .then(response => {
      if (response.status !== 200 || response.data.status === 'error') throw response
      context.commit('endLoading')
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function activateAccount (context, params) {
  context.commit('startLoading')

  return ApiService.post(URLS.API.AUTH.ACTIVATE_ACCOUNT, params)
    .then(response => {
      if (response.status !== 200 || (typeof response.data.status !== 'undefined' && response.data.status === 'error')) throw response
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

// Login

export function loginOffice () {

}
