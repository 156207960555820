import { getter } from '@/services/store/utils'
import ApiService from '@/services/api.service'
import URLS from '@/config/urls'

export function getCourses (context) {
  return getter(context, URLS.API.ACADEMY.GET_COURSES, 'setCourses')
}

export function getCourseDetail (context, id) {
  context.commit('startLoading')
  context.commit('setCourseDetail', {})
  return ApiService.query(URLS.API.ACADEMY.GET_COURSE_DETAIL(id))
    .then(({ data }) => {
      context.commit('setCourseDetail', data)
    })
    .then(_ => { context.commit('endLoading') })
}
