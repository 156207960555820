export default function () {
  return {
    loading: false,
    canSeeCareers: (process.env.VUE_APP_CAN_SEE_CAREERS === 'true') || false,
    canSeeUserConsole: null, // TO Allow users to access /temrinal
    canSeeVpn: false,

    vpnStatus: false,

    authConfig: {
      useSignUpToken: false
    }
  }
}
