import { getter } from '@/services/store/utils'

import URLS from '@/config/urls'
import ApiService from '@/services/api.service'

export function getLabs (context) {
  return getter(context, URLS.API.LABS.GET_LABS, 'setLabs')
}

export function getLabDetail (context, id) {
  return getter(context, URLS.API.LABS.GET_LAB_DETAIL(id), 'setLabDetail')
}

export function getCurrentLabMachine (context, params) {
  context.commit('startLoading')
  return ApiService.query(URLS.API.LABS.MACHINE.GET_DETAIL(params.lab, params.machine))
    .then(({ data }) => {
      context.commit('setCurrentLabMachine', data.data)
    })
    .catch((err) => {
      console.error('Getter', err)
    })
    .then(_ => { context.commit('endLoading') })
}

export function getRecentLabs (context) {
  return getter(context, URLS.API.LABS.GET_RECENT_LABS, 'setRecentLabs')
}

export function insertFlag (context, params) {
  context.commit('startLoading')
  return ApiService.post(URLS.API.LABS.MACHINE.INSERT_FLAG(params.lab, params.machine), { flag: params.flag })
    .then(response => {
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      return err
    })
}

export function unlockHint (context, params) {
  context.commit('startLoading')
  return ApiService.post(URLS.API.LABS.MACHINE.UNLOCK_HINT(params.lab, params.machine))
    .then(response => {
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      return err
    })
}

export function getVpnConnectionStatus (context, id) {
  return ApiService.query(URLS.API.LABS.VPN.GET_VPN_CONNECTION_STATUS(id))
    .then(response => {
      if (response.status === 200 && response.data.status === 'ok') {
        context.commit('setVpnConnectionStatus', response.data.data)
      }
    })
}

// ------------------------ Machine's actions ------------------------

export function startMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.LABS.MACHINE.START_MACHINE(params.lab, params.machine))
    .then(response => { if (response.status !== 200 || response.data.status === 'error') throw response })
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function restartMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.LABS.MACHINE.RESTART_MACHINE(params.lab, params.machine))
    .then(response => { if (response.status !== 200 || response.data.status === 'error') throw response })
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function revertMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.LABS.MACHINE.REVERT_MACHINE(params.lab, params.machine))
    .then(response => { if (response.status !== 200 || response.data.status === 'error') throw response })
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function stopMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.LABS.MACHINE.STOP_MACHINE(params.lab, params.machine))
    .then(response => { if (response.status !== 200 || response.data.status === 'error') throw response })
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

// ------------------------ User machine actions ------------------------
export function startConsole (context) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.LABS.CONSOLE.START)
    .then(response => {
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function restartConsole (context) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.LABS.CONSOLE.RESTART)
    .then(response => {
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function revertConsole (context) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.LABS.CONSOLE.REVERT)
    .then(response => {
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function checkConsoleStatus (context) {
  return ApiService
    .query(URLS.API.LABS.CONSOLE.STATUS)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw err
    })
}
