export default function () {
  return {
    categories: [],
    categoryDetail: {},
    workRoleDetail: {},
    completedCategoriesNice: [],
    completedCategoriesMitre: [],
    pillDetail: {},
    loading: false
  }
}
