export function isLoading (state) {
  return state.loading
}

export function canSeeCareers (state) {
  return state.canSeeCareers
}

export function canSeeUserConsole (state) {
  return state.canSeeUserConsole
}

export function canSeeVpn (state) {
  return state.canSeeVpn
}

export function getVpnStatus (state) {
  return state.vpnStatus
}

export function connectedToVpn (state) {
  return !!state.vpnStatus
}

export function getAuthConfig (state) {
  return state.authConfig
}
