export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function setLabDetail (state, labDetail) {
  state.labDetail = labDetail
}

export function setRecentLabs (state, recentLabs) {
  state.recentLabs = recentLabs
}

export function setCurrentLabMachine (state, currentLabMachine) {
  state.currentLabMachine = currentLabMachine
}

export function setVpnConnectionStatus (state, vpnConnectionStatus) {
  state.vpnConnectionStatus = vpnConnectionStatus
}

export function setLabs (state, labs) {
  state.labs = labs
}
