export function setAnnouncements (state, announcements) {
  state.announcements = announcements
}

export function setFaqs (state, faqs) {
  state.faqs = faqs
}

export function setAbilitiesMitre (state, abilitiesMitre) {
  state.abilities_mitre = abilitiesMitre
}

export function setAbilitiesNice (state, abilitiesNice) {
  state.abilities_nice = abilitiesNice
}

export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function setClientSubdomain (state, subdomain) {
  state.clientSubdomain = subdomain
}

export function setHomeStats (state, homeStats) {
  state.homeStats = homeStats
}
