const ID_TOKEN_KEY = 'id_token'
const ID_REFRESH_TOKEN_KEY = 'refresh_token'
const TIMESTAMP = 'timestamp'

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const getRefreshToken = () => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY)
}

export const getTimeStamp = () => {
  return window.localStorage.getItem(TIMESTAMP)
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
  window.localStorage.setItem(TIMESTAMP, (new Date()).getTime())
}

export const saveRefreshToken = token => {
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY)
  window.localStorage.removeItem(TIMESTAMP)
}

export default { getToken, saveToken, saveRefreshToken, getTimeStamp, getRefreshToken, destroyToken }
