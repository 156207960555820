import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth/index'
import academy from './academy/index'
import staticInfo from './staticInfo/index'
import labs from './labs/index'
import navigation from './navigation/index'
import categories from './categories/index'
import user from './user/index'
import careers from './careers/index'
import objectives from './objectives/index'
import pills from './pills/index'
import config from './config/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    academy,
    staticInfo,
    labs,
    navigation,
    categories,
    user,
    careers,
    objectives,
    pills,
    config
  }
})
