import JwtService from '@/services/jwt.service'

export default function () {
  return {
    loading: false,
    refreshing: false,
    errors: [],
    user: {},
    isAuthenticated: !!JwtService.getToken(),
    csrfToken: ''
  }
}
