
export function setCourses (state, courses) {
  state.courses = courses
}

export function setCourseDetail (state, course) {
  state.courseDetail = course
}

export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}
