import ApiService from '@/services/api.service'
import URLS from '@/config/urls'

export function getGeneralConfig (context, params) {
  context.commit('startLoading')
  return ApiService
    .query(URLS.API.CONFIG.GENERAL)
    .then(response => {
      if (response.status !== 200) {
        throw Error()
      }
      context.commit('setGeneralConfig', response.data.data)
      context.commit('endLoading')
      return response.data
    })
    .catch(err => {
      throw err
    })
}

export function getAuthConfig (context) {
  return ApiService.query(URLS.API.CONFIG.AUTH)
    .then(response => {
      if (response.status !== 200) {
        throw Error()
      }

      context.commit('setAuthConfig', response.data)
      return response.data
    })
}

// ---------- VPN -----------------------------
export function getVpnConnectionStatus (context) {
  return ApiService.query(URLS.API.LABS.VPN.GET_VPN_CONNECTION_STATUS)
    .then(response => {
      if (response.status === 200 && response.data.status === 'ok') {
        context.commit('setVpnConnectionStatus', response.data.data)
      }
    })
}
