export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function setCareers (state, careers) {
  state.careers = careers
}

export function setCareerDetail (state, careerDetail) {
  state.careerDetail = careerDetail
}
