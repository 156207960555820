import Vue from 'vue'
import router from '../router'
import store from './store/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/services/jwt.service'
import i18nService from '@/services/i18n.service'

import { BASE_URL } from '@/config/urls'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = BASE_URL
    this.setHeader()

    /**
     * Used to auto renew the user token
     */
    axios.interceptors.request.use(
      async function (request) {
        if (!store.getters['auth/isRefreshing']) {
          await store.dispatch('auth/verifyAuth')
        }

        request.headers.common['Accept-Language'] = i18nService.getActiveLanguage()
        return request
      }
    )

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (error.response) {
        if (error.response.status === 401 && router.currentRoute.name !== 'login') {
          store.dispatch('auth/logout')
          router.push({ name: 'login' })
        }
        // if (error.response.status === 404) router.push('/404')
        // if (error.response.status === 500) router.push('/500')
      }

      return Promise.reject(error)
    })
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader () {
    if (JwtService.getToken() === null) return

    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`
  },

  query (resource, params) {
    return Vue.axios.get(resource, { params: params }).catch(error => {
      // if (error.response && error.response.status === 404) router.push('/404')
      if (error.response && error.response.status === 500) router.push('/500')

      throw error
    })
  },

  queryWithConfig (resource, params, config) {
    return Vue.axios.get(resource, { ...config, params: params }).catch(error => {
      // if (error.response && error.response.status === 404) router.push('/404')
      if (error.response && error.response.status === 500) router.push('/500')

      throw error
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get (resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.log(error)
      throw new Error(`ApiService ${error}`)
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post (resource, params) {
    return Vue.axios.post(`${resource}`, params)
  },

  postWithConfig (resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config)
  },

  postImg (resource, params) {
    return Vue.axios.post(`${resource}`, params, { header: { 'Content-Type': 'image/png' } })
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update (resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put (resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete (resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error)
      throw new Error(`ApiService ${error}`)
    })
  },

  getImg (path) {
    return Vue.axios.get(`${path}`, { responseType: 'blob' })
  }
}

export default ApiService
