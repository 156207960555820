<template>
  <b-card
    :img-src="img"
    :img-alt="title"
    :img-top="!!img"
    :class="{ 'no-shadow': noShadow, 'no-padding': noPadding, linkable: !noHover && hasTo && !disabled, clickable: noHover && hasTo && !disabled }"
    @click="onClick"
  >
    <template #header v-if="isHeaderPresent">
      <slot name="header" >
      </slot>
    </template>

    <slot name="title">
      <b-card-title>
        {{ title }}
      </b-card-title>
    </slot>

    <slot name="content">
      <b-card-text class="t-justify">
        {{ text }}
      </b-card-text>
    </slot>

    <template #footer v-if="isFooterPresent">
      <slot name="footer" >
      </slot>
    </template>
  </b-card>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    img: {
      type: String,
      required: false,
      default: ''
    },
    to: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    'no-shadow': {
      type: Boolean,
      required: false,
      default: false
    },
    'no-padding': {
      type: Boolean,
      required: false,
      default: false
    },
    'no-hover': {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return { }
  },
  methods: {
    onClick () {
      if (!this.hasTo || this.disabled) return

      this.$router.push(this.to)
    }
  },
  computed: {
    isFooterPresent () { return Boolean(this.$slots.footer) },
    isHeaderPresent () { return Boolean(this.$slots.header) },
    hasTo () { return Object.keys(this.to).length > 0 }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    background-color: white;
    border: none;
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);

    &.no-shadow {
      border: 1px solid $grey;
      box-shadow: unset;
    }

    &.no-padding {
      .card-body {
        padding: 0;
      }
    }

    .card-header {
      border-bottom: none;
    }
    .card-title {
      margin-bottom: unset;
    }

    .card-footer {
      border-top: none;
    }
  }

  .linkable {
    cursor: pointer;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    &:hover {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }
</style>
