export default function () {
  return {
    loading: false,
    clientSubdomain: '',
    announcements: [],
    abilities_mitre: [],
    abilities_nice: [],
    faqs: [
    ],
    homeStats: {
      mitre_total: 0,
      mitre_completed: 0,
      nice_total: 0,
      nice_completed: 0,
      pills_total: 0,
      pills_completed: 0,
      vms_total: 0,
      vms_completed: 0,
      categories_nice: []
    },
    work_roles_nice: [
      { value: 1, text: 'Authorizing Official/Designating Representative' },
      { value: 2, text: 'Security Control Assessor' },
      { value: 3, text: 'Software Developer' },
      { value: 4, text: 'Secure Software Assessor' },
      { value: 5, text: 'Enterprise Architect' },
      { value: 6, text: 'Security Architect' },
      { value: 7, text: 'Research & Development Specialist' },
      { value: 8, text: 'Systems Requirements Planner' },
      { value: 9, text: 'System Testing and Evaluation Specialist' },
      { value: 10, text: 'Information Systems Security Developer' },
      { value: 11, text: 'Systems Developer' },
      { value: 12, text: 'Database Administrator' },
      { value: 13, text: 'Data Analyst' },
      { value: 14, text: 'Knowledge Manager' },
      { value: 15, text: 'Technical Support Specialist' },
      { value: 16, text: 'Network Operations Specialist' },
      { value: 17, text: 'System Administrator' },
      { value: 18, text: 'Systems Security Analyst' },
      { value: 19, text: 'Cyber Legal Advisor' },
      { value: 20, text: 'Privacy Officer/Privacy Compliance Manager' },
      { value: 21, text: 'Cyber Instructional Curriculum Developer' },
      { value: 22, text: 'Cyber Instructor' },
      { value: 23, text: 'Information Systems Security Manager' },
      { value: 24, text: 'Communications Security (COMSEC) Manager' },
      { value: 25, text: 'Cyber Workforce Developer and Manager' },
      { value: 26, text: 'Cyber Policy and Strategy Planner' },
      { value: 27, text: 'Executive Cyber Leadership' },
      { value: 28, text: 'Program Manager' },
      { value: 29, text: 'IT Project Manager' },
      { value: 30, text: 'Product Support Manager' },
      { value: 31, text: 'IT Investment/Portfolio Manager' },
      { value: 32, text: 'IT Program Auditor' },
      { value: 33, text: 'Cyber Defense Analyst' },
      { value: 34, text: 'Cyber Defense Infrastructure Support Specialist' },
      { value: 35, text: 'Cyber Defense Incident Responder' },
      { value: 36, text: 'Vulnerability Assessment Analyst' },
      { value: 37, text: 'Threat/Warning Analyst' },
      { value: 38, text: 'Exploitation Analyst' },
      { value: 39, text: 'All-Source Analyst' },
      { value: 40, text: 'Mission Assessment Specialist' },
      { value: 41, text: 'Target Developer' },
      { value: 42, text: 'Target Network Analyst' },
      { value: 43, text: 'Multi-Disciplined Language Analyst' },
      { value: 44, text: 'All Source-Collection Manager' },
      { value: 45, text: 'All Source-Collection Requirements Manager' },
      { value: 46, text: 'Cyber Intel Planner' },
      { value: 47, text: 'Cyber Ops Planner' },
      { value: 48, text: 'Partner Integration Planner' },
      { value: 49, text: 'Cyber Operator' },
      { value: 50, text: 'Cyber Crime Investigator' },
      { value: 51, text: 'Law Enforcement /CounterIntelligence Forensics Analyst' },
      { value: 52, text: 'Cyber Defense Forensics Analyst' }
    ],

    dummyProfilePic: '/media/img/dummy_profile.png'
  }
}
