export function isLoading (state) {
  return state.loading
}

export function getObjectives (state) {
  return state.objectives
}

export function getObjectivesFirstLevel (state) {
  return state.objectivesFirstLevel
}

export function getObjectiveDetail (state) {
  return state.objectiveDetail
}
