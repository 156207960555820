export const locale = {
  hello: 'Hallo Welt',
  GENERAL: {
    titulo_bienvenida: 'Willkommen zu Academy!',
    hello: 'Hallo',
    quick_actions: 'Schnelle Aktionen',
    go_to_profile: 'Profil aufrufen',
    information: 'Information',
    second: 'Sekunde',
    seconds: 'Sekunden',
    minute: 'Minute',
    minutes: 'Minuten',
    hour: 'Stunde',
    hours: 'Stunden',
    points: 'Punkte',
    select_one: 'Einen auswählen',
    faqs: 'FAQs',
    faqs_full: 'Häufige Fragen',
    no_results: 'Es gibt keine Ergebnisse',
    no_results_sub: 'Es scheint, dass kein Element verfügbar ist',
    congratulations: 'Herzlichen Glückwunsch!',
    loading: 'Lädt',
    back_to_home: 'Zurück zum Anfang',
    title_error: 'Fehler!',
    actions: 'Aktionen',
    home: 'Start',
    academy: 'Academy',
    see_more: 'Mehr anzeigen',
    oops: 'Ups',
    show_more: 'Mehr anzeigen',
    show_less: 'Weniger anzeigen',
    no_selection: 'Keine Auswahl',
    completed_fem: 'Abgeschlossen',
    pending: 'Ausstehend',
    currentBlockedMachine: 'Computer wird verwendet',
    LANGS: {
      Spanish: 'Spanisch',
      English: 'Englisch',
      German: 'Deutsche'
    },
    COUNTRIES: {
      Spain: 'Spanien'
    }
  },
  ACTIONS: {
    add: 'Hinzufügen',
    back: 'Zurück',
    continue: 'Weiter',
    download: 'Herunterladen',
    send: 'Absenden',
    show: 'Anzeigen',
    collapse: 'Abbrechen',
    expand: 'Erweitern',
    start: 'Beginnen',
    reset: 'Neu starten',
    connect_to: 'Sich verbinden',
    see_details: 'Siehe Details',
    insert_flag: 'Flagge einfügen',
    start_machine: 'Maschine starten',
    stop_machine: 'Maschine stoppen',
    lock_machine: 'Computer besetzen',
    unlock_machine: 'Computer freisetzen',
    report_issue: 'Zwischenfall melden',
    cancel: 'Abbrechen',
    accept: 'Annehmen',
    do_again: 'Wiederholen',
    finish: 'Abschließen',
    show_list: 'Liste anzeigen',
    search: 'Suche',
    refresh: 'Neu laden'
  },
  TOASTS: {
    title_success: 'Geschafft!',
    title_error: 'Fehler!'
  },
  TERMS: {
    pills: 'Aufgaben',
    completed_pills: 'Vollständige Aufgaben',
    objective: 'Ziel',
    objectives: 'Ziele',
    flag: 'Flagge',
    labs: 'Labore',
    machines: 'Maschinen',
    pending_machines: 'Ausstehende Maschinen',
    labs_full: 'Laboratorien',
    careers: 'Technische Karrieren',
    categories_nice: 'NICE-Kategorien',
    categories_mitre: 'MITRE-Kategorien',
    techniques_mitre: 'MITRE-Techniken',
    vpn: 'VPN',
    terminal: 'Endgerät',
    hints: 'Tipps',
    offline: 'Offline',
    issue: 'Vorfall',
    work_roles: 'Arbeitsrollen',
    courses: 'Kurse',
    resources: 'Hilfsmittel',
    console: 'Konsole',
    NICE: {
      knowledge: 'NICE-Kenntnis',
      knowledges: 'NICE-Kenntnisse',
      ability: 'NICE-Kapazität',
      abilities: 'NICE-Kapazitäten',
      skill: 'NICE-Fähigkeit',
      skills: 'NICE-Fähigkeiten',
      no_completed: 'Du hast noch keine NICE-Kategorie abgeschlossen',
      all_completed: 'Du hast alle NICE-Kategorien abgeschlossen!'
    }
  },
  HOME: {
    greeting: 'Willkommen zurück bei CyberAcademy+',
    careers_not_available: 'Es gibt keine verfügbaren Kategorien'
  },
  PILLS: {
    name: 'Name',
    description: 'Beschreibung',
    status: 'Status',
    type: 'Typ',
    difficulty: 'Schwierigkeit',
    points: 'Punkte',
    score: 'Bewertung',
    lessons: 'Lektionen',
    duration: 'Laufzeit',
    recent: 'Aktuelle Aufgaben',
    associated: 'Dazugehörige Aufgaben',
    no_results: 'Es gibt keine Pillen, die Ihren Kriterien entsprechen',
    no_pills_yet: 'Du hast noch nicht begonnen, Aufgaben zu lösen',
    no_recent_pills: 'Keine aktuellen Aufgaben',
    list: 'Liste der Aufgaben',
    list_description: 'Hier findest du eine Liste aller Aufgaben, die auf der Plattform angeboten werden',
    DETAIL: {
      work_roles_text: 'Das Wissen, das durch den Abschluss dieser Aufgabe erworben wird, steht im Zusammenhang mit den folgenden Berufsrollen',
      techniques_mitre_text: 'Bei der Ausarbeitung dieser Aufgabe wirst du eine Reihe von MITRE-Taktiken anwenden. Im Folgenden kannst du sie dir ansehen',
      objectives_extra_text: 'Durch die Bearbeitung dieser Aufgaben machst du Fortschritte zu den nächsten Zielen',
      knowledges_text: 'Durch die Bearbeitung dieser Aufgaben machst du Fortschritte zu den nächsten NICE-Kenntnissen',
      abilities_text: 'Durch die Bearbeitung dieser Aufgaben machst du Fortschritte zu den nächsten NICE-Kapazitäten',
      skills_text: 'Durch die Bearbeitung dieser Aufgaben machst du Fortschritte zu den nächsten NICE-Fähigkeiten'
    },
    EXECUTION: {
      progress: 'Fortschritt der Aufgabe',
      invalid_answer: 'Falsche Antwort',
      no_resources: 'Es gibt nichts anzuzeigen',
      no_resources_desc: 'Diese Aufgabe hat keine zugehörigen Materialien',
      no_questions_description: 'Es scheint, dass diese Aufgabe keine zugehörigen Fragen hat. Wenn du die Aufgaben erledigt hast, kannst du auf "Beenden" klicken',
      donwload_description: 'Klicke auf die Schaltfläche, um die Materialien herunterzuladen',
      result_title: 'Aufgabe beendet!',
      result_description: 'Wir hoffen, dass sie dir gefallen hat und dass du etwas Nützliches gelernt hast.',
      back_to_pill_list: 'Zurück zur Liste der Aufgaben',
      timer_explanation: 'Verbleibende Zeit. Nach Ablauf des Zählers empfiehlt es sich, die Seite zu aktualisieren, da einige Materialien abgelaufen sein können',
      time_end_title: 'Die Zeit für die Aufgabe ist abgelaufen',
      time_end_text: 'Einige Materialien werden möglicherweise nicht korrekt geladen. Falls du Probleme hast, solltest du die Seite aktualisieren'
    },
    DIFFICULTIES: {
      '0-verylow': 'Sehr niedrig',
      '1-low': 'Niedrig',
      '2-medium': 'durchschnittlich',
      '3-high': 'Hoch',
      '4-extreme': 'Extrem'
    },
    TYPES: {
      theory: 'Theorie',
      practice: 'Praxis'
    },
    STATUS: {
      pending: 'Ausstehend',
      in_progress: 'Wird bearbeitet',
      completed: 'Abgeschlossen'
    },
    ERRORS: {
      start_pill: 'Beim Start der Aufgabe ist ein Fehler aufgetreten',
      video_not_suported: 'Video wird nicht unterstützt',
      has_pending_vms: 'Bevor du die Aufgabe fertigstellst, musst du die dazugehörigen Maschinen fertigstellen',
      general: 'Es ist ein Fehler aufgetreten'
    }
  },
  HINTS: {
    unlock: 'Tipp freigeben',
    no_unlocked: 'Es gibt keine freigegebenen Tipps',
    machine_has_no_hints: 'Die Maschine hat keine zugehörigen Tipps',
    unlock_modal_description: 'Bist du sicher, dass du einen neuen Tipp freigeben möchtest?'
  },
  LAB_MACHINE: {
    confirm_revert_title: 'Maschine umkehren',
    confirm_revert_text: 'Bist du sicher, dass du den ursprünglichen Zustand der Maschine wiederherstellen willst? Diese Aktion kann nicht rückgängig gemacht werden',
    confirm_restart_title: 'Maschine neu starten',
    confirm_restart_text: 'Bist du sicher, dass du die Maschine neu starten möchtest? Diese Aktion kann nicht rückgängig gemacht werden',
    confirm_stop_title: 'Maschine stoppen',
    confirm_stop_text: 'Bist du sicher, dass du die Maschine stoppen möchtest? Diese Aktion kann nicht rückgängig gemacht werden',
    confirm_unlock_title: 'Computer freisetzen',
    confirm_unlock_text: 'Sind Sie sicher, dass Sie den Computer freisetzen wollen? Diese Aktion kann nicht rückgängig gemacht werden',
    locked_by_other: 'Von einem anderen Schüler besetzt',

    ACTIONS: {
      revert: 'Umkehren',
      restart: 'Neu starten',
      stop: 'Stoppen',
      restart_success: 'Die Maschine wurde erfolgreich neu gestartet',
      revert_success: 'Die Maschine wurde erfolgreich umgekehrt',
      stop_success: 'Die Maschine wurde erfolgreich gestoppt',
      UNLOCK_HINT_success: 'Tipp wurde erfolgreich freigegeben',
      start_machine_success: 'Die Maschine wurde erfolgreich gestartet',
      unlock_machine_success: 'Computer wurde erfolgreich freigesetzt',
      no_actions_because_blocked_by_other_user: 'Keine Aktionen verfügbar, weil der Computer von einem anderen Benutzer verwendet wird',
      no_actions_because_you_already_have_other_machine: 'Keine Aktionen verfügbar, weil Sie bereits einen anderen Computer besetzt haben'
    },
    STATUS: {
      pending: 'Ausstehend',
      in_progress: 'Wird bearbeitet',
      completed: 'Abgeschlossen'
    },
    ERRORS: {
      revert: 'Bei der Umkehrung der Maschine ist etwas passiert. Versuchen Sie es später erneut',
      max_reverts_per_student_and_day_matched: 'Du hast bereits alle Neustarts durchgeführt, die du heute durchführen konntest. Versuche es morgen erneut',
      restart: 'Beim Neustart der Maschine ist etwas passiert. Versuchen Sie es später erneut',
      start: 'Beim Start der Maschine ist etwas passiert. Versuchen Sie es später erneut',
      stop: 'Beim Stopp der Maschine ist etwas passiert. Versuchen Sie es später erneut',
      general: 'Es ist ein Fehler aufgetreten',
      UNLOCK_HINT: 'Bei der Freigabe der Maschine ist etwas passiert. Versuchen Sie es später erneut'
    }
  },
  STUDENT_MACHINE: {
    confirm_revert_title: 'Maschine umkehren',
    confirm_revert_text: 'Bist du sicher, dass du den ursprünglichen Zustand der Maschine wiederherstellen willst? Diese Aktion kann nicht rückgängig gemacht werden',
    confirm_restart_title: 'Maschine neu starten',
    confirm_restart_text: 'Bist du sicher, dass du die Maschine neu starten möchtest? Diese Aktion kann nicht rückgängig gemacht werden',
    ACTIONS: {
      revert: 'Umkehren',
      restart: 'Neu starten'
    },
    iframe_has_focus: 'Die Konsole hat Fokus',
    iframe_doesnt_has_focus: 'Die Konsole hat keinen Fokus',
    ERRORS: {
      no_machine: 'Beim Laden deiner Maschine scheint etwas passiert zu sein',
      revert: 'Bei der Umkehrung der Maschine ist etwas passiert. Versuchen Sie es später erneut',
      restart: 'Beim Neustart der Maschine ist etwas passiert. Versuchen Sie es später erneut'
    },
    LOADING_STATUS: {
      reverting: 'Rückgängig machen...',
      restarting: 'Neu starten...',
      starting: 'Maschine starten...',
      starting_os: 'Betriebssystem starten...',
      ready: 'Maschine bereit'
    }
  },
  MACHINES: {
    so: 'BS',
    connection_title: 'Verbindungsdaten',
    no_connection_info: 'Für diese Maschine liegen keine Verbindungsdaten vor',
    user: 'Benutzer',
    pass: 'Kennwort',
    ip: 'IP',
    pills_associated_description: 'Der Abschluss dieser Maschine schaltet die unten gezeigten Aufgaben frei',
    categories_mitre_associated: 'Zugehörige MITRE-Kategorien',
    no_categories_mitre_associated: 'Es gibt keine zugehörigen MITRE-Kategorien',
    categories_nice_associated: 'Zugehörige NICE-Kategorien',
    no_categories_nice_associated: 'Es gibt keine zugehörigen NICE-Kategorien',
    complete_message: 'Herzlichen Glückwunsch! Du hast diese Maschine bereits abgeschlossen',
    FLAGS: {
      valid: 'Die Flagge ist korrekt',
      invalid: 'Falsche Flagge',
      error: 'Bei der Eingabe des Flags ist ein Fehler aufgetreten'
    }
  },
  LABS: {
    tab_description: 'Übe das Gelernte',
    name: 'Name',
    status: 'Status',
    recent: 'Aktuelle Laboratorien',
    no_recent_labs: 'Es gibt keine aktuellen Laboratorien',
    open_console: 'Benutzerterminal öffnen',
    open_console_description: 'Für dieses Labor steht dir eine Maschine zur Verfügung, die du benutzen kannst',
    lab_complete_progress: 'Abgeschlossen',
    section_description: 'In diesem Abschnitt kannst du mit unterschiedlichen Maschinen interagieren, um das gelernte Wissen anzuwenden',
    ERRORS: {
      general: 'Beim Start der Konsole ist ein Fehler aufgetreten'
    }
  },
  CAREERS: {
    summary: 'Zusammenfassung deiner technischen Karriere'
  },
  CERTIFICATES: {
    name: 'Name',
    status: 'Status',
    add_certification: 'Zertifizierung hinzufügen',
    success_adding: 'Die Zertifizierung wurde erfolgreich hinzugefügt',
    error_adding: 'Die Zertifizierung konnte nicht hinzugefügt werden'
  },
  AUTH: {
    sign_in: 'Einloggen',
    sign_in_m365: 'Einloggen mit M365',
    enter_your_username_and_password: 'Gib deinen Benutzernamen und dein Kennwort ein',
    forgot_password: 'Hast du dein Kennwort vergessen?',
    forgot_password_description: 'Gib deine E-Mail-Adresse ein. Falls es einen Benutzer gibt, senden wir dir eine E-Mail mit Anweisungen, wie du dein Passwort ändern kannst',
    email_required: 'Die E-Mail-Adresse ist obligatorisch und muss gültig sein',
    passwqord_required: 'Das Kennwort ist obligatorisch',
    log_out: 'Sich abmelden',
    sign_up: 'Registrieren',
    sign_up_text: 'Du hast kein Konto? Registriere dich!',
    already_registered: 'Du hast bereits ein Konto? Logge dich ein!',
    sign_up_error: 'Es ist ein Fehler aufgetreten',
    select_certificacions_description: 'Du kannst dann deine aktuellen Zertifizierungen eingeben. Dadurch kennzeichnen wir die zugehörigen Aufgaben als abgeschlossen',
    extra_select_certificacions_description: 'Falls du eine Zertifizierung hast, die nicht in der Liste enthalten ist, gib sie bitte unten an',
    extra_certificacions_placeholder: 'Offizieller Name der Zertifizierung, durch Komma getrennt',
    already_know_your_password: 'Weisst du dein Kennwort? Logge dich ein!',
    forgot_password_success: 'Deine Anfrage wurde erfolgreich bearbeitet. Wir haben eine E-Mail mit Anweisungen an deine E-Mail-Adresse geschickt.',
    forgot_password_error: 'Es ist ein Fehler aufgetreten',
    set_password_success: 'Deine Anfrage wurde erfolgreich bearbeitet. Du kannst dich jetzt mit deinem neuen Kennwort anmelden.',
    set_password_error: 'Es ist ein Fehler aufgetreten',
    confirm_account_description: 'Ihr Konto wurde erfolgreich erstellt. Bevor du dich einloggen kannst, musst du dein Konto aktivieren. Wir haben dir eine E-Mail an das angegebene Konto geschickt.',
    confirm_account_resend_success: 'Wir haben die Bestätigungs-E-Mail erneut gesendet',
    confirm_account_resend_error: 'Beim erneuten Senden der E-Mail ist ein Fehler aufgetreten. Versuch es später erneut',
    validated_account_description: 'Ihr Konto wurde erfolgreich validiert. Du kannst dich jetzt bei der Plattform anmelden.',
    back_to_sign_in: 'Zurück zum Login',
    activate_account_description: 'Bald kannst du dein Konto benutzen! Erstelle einfach ein neues Kennwort und du kannst auf alle Inhalte zugreifen',
    activate_account_success_description: 'Dein Konto wurde erfolgreich aktiviert, du kannst dich jetzt bei der Plattform anmelden',
    signup_token: 'Anmeldungs-Token',
    ERRORS: {
      invalid_client_platform_for_student: 'Ungültiger Benutzer für diese Kundenplattform',
      invalid_client_platform: 'Kundenplattform nicht gültig',
      invalid_credentials: 'Ungültige Anmeldedaten',
      general: 'Fehler beim Anmelden',
      email_already_used: 'Es existiert bereits ein Benutzer mit dieser Email-Adresse',
      domain_not_included_for_sign_up: 'Keine zulässige E-Mail-Domain',
      email_domain_blocked: 'Keine zulässige E-Mail-Domain',
      general_sign_up: 'Registerfehler',
      csrf_invalid: 'Das CSRF-Token ist ungültig. Aktualisiere die Seite und versuche es erneut',
      missing_student_with_token: 'Es wurde kein mit diesem Token verbundener Benutzer gefunden',
      user_is_not_pending_of_activation: 'Der Benutzer ist nicht zur Aktivierung ausstehend',
      pending_activation: 'Der Benutzer wurde noch nicht verifiziert',
      token_already_used: 'Dieser Token wurde bereits zur Aktivierung des Kontos verwendet',
      token_missing: 'Zur Aktivierung des Kontos ist ein Token erforderlich',
      weak_password: 'Das Kennwort ist zu schwach',
      domain_not_included: 'Fehler beim Anmelden. Es ist wahrscheinlich, dass die Domain deines E-Mail-Kontos nicht unter den auf deiner Plattform akzeptierten Domains aufgeführt ist. Konsultiere deinen Administrator',
      domain_blocked: 'Fehler beim Anmelden. Es ist wahrscheinlich, dass die Domain deines E-Mail-Kontos nicht zugelassen ist',
      oauth_missing_params: 'Fehler beim Anmelden. Es fehlen notwendige Parameter, um den Prozess fortzusetzen. Sollte das Problem weiterbestehen, konsultiere einen Administrator',
      license_limit_reached: 'Die maximale Anzahl der Lizenzen für diesen Kunden wurde erreicht',
      disabled_account: 'Konto inaktiv',
      invalid_value: 'Wert ungültig'
    }
  },
  VPN: {
    info: 'Informationen zum VPN',
    info_text_1: 'In manchen Szenarien ist es erforderlich, sich über VPN mit dem Labornetz zu verbinden, um bestimmte Fehler zu beheben',
    info_text_2: 'Nachstehend kannst du dein OpenVPN-Profil für dieses Laboratorium herunterladen',
    status: 'Status',
    download_profile: 'Profil herunterladen',
    connection_status: 'Verbindungsstatus',
    connected_from: 'Verbunden von',
    virtual_ip: 'Virtuelle IP',
    real_ip: 'Reale IP',
    device: 'Gerät',
    platform: 'Plattform',
    ERRORS: {
      download: 'Fehler beim Herunterladen des VPN-Profils'
    }
  },
  PROFILE: {
    last_login: 'Letzte Anmeldung',
    my_formation: 'Meine Ausbildung',
    info: 'Mein Profil',
    reports: 'Berichte',
    certifications: 'Zertifikate',
    your_activity: 'Meine Aktivität',
    your_activity_description: 'Hier kannst du eine Übersicht darüber sehen, wie viele Aufgaben du in der letzten Woche erledigt hast. Weiter so!',
    lab_report: 'Lab-Bericht',
    objective_report: 'Ziel-Bericht',
    career_report: 'Kerriere-Bericht',
    upload_profile_pic: 'Profilbild hochladen',
    PROGRESS: {
      your_progress: 'Dein Fortschritt',
      your_progress_description: 'In diesem Bereich kannst du sehen, welchen Fortschritt du in den verschiedenen Kategorien gemacht hast'
    },
    CERTIFICATIONS: {
      my_certifications: 'Meine Zertifizierungen',
      my_certifications_desc: 'Diese Zertifizierungen hast du jetzt, herzlichen Glückwunsch!',
      available_certificates: 'Verfügbare Zertifizierungen',
      available_certificates_desc: 'Diese Zertifizierungen sind derzeit auf der Plattform verfügbar. Falls du eines davon hast, kontaktiere bitte die Verwaltung, um es deinem Profil hinzuzufügen'
    },
    FORM: {
      username: 'Name des Benutzers',
      country: 'Land',
      language: 'Sprache',
      ERRORS: {
        username: 'Muss mindestens drei Zeichen lang sein',
        error_uploading_pic: 'Fehler beim Hochladen eines Fotos'
      }
    }
  },
  TABLE: {
    first: 'Erstes',
    last: 'Letztes',
    next: 'Weiter',
    previous: 'Zurück',
    items_per_page: 'Element pro Seite',
    items_total: 'Elemente insgesamt'
  },
  FORMS: {
    name: 'Name',
    email: 'E-Mail-Adresse',
    password: 'Kennwort',
    confirm_password: 'Kennwort bestätigen',
    length_requirement: 'Muss mindestens {n} Zeichen lang sein',
    max_length_requirement: 'Muss weniger als {n} Zeichen lang sein',
    password_requirements: 'Das Kennwort muss aus mindestens einem Kleinbuchstaben, einem Großbuchstaben, einer Zahl und einem Sonderzeichen bestehen',
    same_password: 'Kennwörter müssen übereinstimmen',
    required: 'Erforderlich',
    update_success: 'Daten wurden erfolgreich aktualisiert',
    ERRORS: {
      name_length: 'Muss mindestens drei Zeichen lang sein',
      generic: 'Beim Senden des Formulars ist ein Fehler aufgetreten'
    }
  },
  FOOTER: {
    content: '©{year} CYBERACADEMY+. ALLE RECHTE VORBEHALTEN.'
  },
  RRSS: {
    twitter: 'https://twitter.com/ihacklabs',
    facebook: 'https://www.facebook.com/ihacklabs',
    contact: 'support@ihacklabs.com'
  },
  LEGAL: {
    privacidad: 'Privatsphäre',
    legal: 'Rechtshinweise',
    contacto: 'Kontakt',
    PRIVACY: {
      title: 'Datenschutzpolitik'
    },
    TERMS_AND_CONDITIONS: {
      title: 'Allgemeinen Geschäftsbedingungen'
    },
    USE_POLICY: {
      title: 'Nutzungsbedingungen'
    },
    COOKIE_POLICY: {
      title: 'Cookie-Richtlinie'
    }
  },
  PAGES: {
    dashboard: 'Dashboard',
    home: 'Start',
    objectives: 'Ziele',
    objectiveDetail: 'Ziel-Übersicht',
    categories: 'Academy',
    careers: 'Technische Karrieren',
    careerDetail: 'Übersicht über die technische Karriere',
    courses: 'Kurse',
    courseDetail: 'Übersicht über den Kurs',
    workRoleDetail: 'Übersicht über die Arbeitsrolle',
    labs: 'Labore',
    labDetail: 'Übersicht über Lab',
    machineDetail: 'Übersicht über die Maschine',
    pills: 'Liste der Aufgaben',
    pillDetail: 'Übersicht über die Aufgabe',
    pillExecution: 'Ausführung der Aufgabe',
    pillExecutionResult: 'Ergebnis der Aufgabe',
    terminal: 'Spielekonsole',
    faqs: 'FAQs',
    profileHome: 'Profil',
    profileInfo: 'Benutzerinformationen',
    profileReports: 'Berichte',
    profileVpn: 'VPN',
    profileCertifications: 'Benutzerzertifizierungen',
    login: 'Einloggen',
    register: 'Anmeldung',
    confirmAccount: 'Konto bestätigen',
    confirmAccountEndpoint: 'Konto bestätigen',
    forgotPassword: 'Kennwort-Wiederherstellung',
    forgotPasswordSuccess: 'Kennwort-Wiederherstellung',
    setPassword: 'Kennwort ändern',
    setPasswordSuccess: 'Kennwort ändern',
    privacy: 'Privatsphäre',
    termsAndConditions: 'Allgemeinen Geschäftsbedingungen',
    error: 'Fehler',
    oauthCallback: 'Oauth',
    mitreByUser: 'MITRE-Kategorien',
    niceByUser: 'NICE-Kategorien',
    activateAccountEndpoint: 'Konto aktivieren',
    activateAccountSuccess: 'Aktiviertes Konto',
    DESCRIPTIONS: {
      mitre_description: 'Hier kannst du die MITRE-Kategorien sehen, die du bereits abgeschlossen hast',
      nice_description: 'Hier kannst du die NICE-Kategorien sehen, die du bereits abgeschlossen hast'
    }
  },
  ISSUES: {
    placeholder: 'Zwischenfall beschreiben...',
    success: 'Zwischenfall erfolgreich gemeldet',
    error: 'Beim Senden des Zwischenfall ist ein Fehler aufgetreten. Versuchen Sie es später erneut'
  },
  ERRORS: {
    general: 'Etwas ist passiert',
    forbidden: 'Material nicht abrufbar',
    not_found: 'Material nicht gefunden',
    video_not_suported: 'Video wird nicht unterstützt',
    cannot_open_new_tab: 'Neue Registerkarte kann nicht geöffnet werden'
  },
  PDFS: {
    CERTIFICATION: {
      certificate: 'Zertifikat',
      generate_certificate: 'Zertifikat generieren',
      given_to: 'Vergeben an',
      total_duration: 'Gesamtdauer des Kurses',
      course_director: 'Leiter des Kurses',
      date_finish: 'Enddatum'
    }
  }
}
