import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Base'),
    children: [
      { path: '', redirect: 'home' },
      { name: 'home', path: '/home', component: () => import('@/views/Home'), meta: { clearBreadcrumbs: true } },

      // Academy
      { name: 'categories', path: '/academy', component: () => import('@/views/academy/Categories'), meta: { includeInBreadcrumb: true } },
      { name: 'categoryDetail', path: '/academy/category/:id', component: () => import('@/views/academy/CategoryDetail'), meta: { includeInBreadcrumb: true } },
      { name: 'courseDetail', path: '/academy/course/:id', component: () => import('@/views/courses/CourseDetail') },

      // Objectives
      { name: 'objectives', path: '/objectives', component: () => import('@/views/objectives/Objectives') },
      { name: 'objectiveDetail', path: '/objectives/:id', component: () => import('@/views/objectives/ObjectiveDetail') },

      // Pills
      { name: 'pills', path: '/pills', component: () => import('@/views/pills/PillList') },
      { name: 'pillDetail', path: '/pills/:id', component: () => import('@/views/pills/PillDetail') },

      // Labs
      { name: 'labs', path: '/labs', component: () => import('@/views/labs/Labs') },
      { name: 'labDetail', path: '/labs/:id', component: () => import('@/views/labs/LabDetail') },
      { name: 'machineDetail', path: '/labs/:id/machine/:idMachine', component: () => import('@/views/labs/MachineDetail') },

      // Careers
      { name: 'careers', path: '/careers', component: () => import('@/views/careers/Careers') },
      { name: 'careerDetail', path: '/careers/:id', component: () => import('@/views/careers/CareerDetail') },
      { name: 'workRoleDetail', path: '/careers/workrole/:id', component: () => import('@/views/academy/WorkRoleDetail'), meta: { includeInBreadcrumb: true } },

      { name: 'mitreByUser', path: '/mitre', component: () => import('@/views/profile/MitreByUser') },
      { name: 'niceByUser', path: '/nice', component: () => import('@/views/profile/NiceByUser') },

      // Profile
      {
        name: 'profile',
        path: '/profile',
        component: () => import('@/layouts/Profile'),
        children: [
          { name: 'profileHome', path: 'general', component: () => import('@/views/profile/ProfileGeneral') },
          { name: 'profileInfo', path: 'info', component: () => import('@/views/profile/ProfileInfo') },
          { name: 'profileReports', path: 'reports', component: () => import('@/views/profile/ProfileReports') },
          { name: 'profileVpn', path: 'vpn', component: () => import('@/views/profile/ProfileVpn') },
          { name: 'profileCertifications', path: 'certifications', component: () => import('@/views/profile/ProfileCertifications') }
        ]
      },

      // FAQs
      { name: 'faqs', path: '/faqs', component: () => import('@/views/static/Faq') }
    ]
  },
  {
    path: '/execution',
    name: 'Executions',
    component: () => import('@/layouts/Executions'),
    children: [
      { name: 'pillExecution', path: 'pill/:id', component: () => import('@/views/pills/PillExecution') },
      { name: 'pillExecutionResult', path: 'pill/:id/result', component: () => import('@/views/pills/PillExecutionResult') }
    ]
  },
  {
    path: '/terminal',
    component: () => import('@/layouts/Terminal'),
    children: [
      { name: 'terminal', path: '', component: () => import('@/views/terminal/Terminal') }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/auth/Auth'),
    children: [
      { name: 'login', path: '/login', component: () => import('@/views/auth/Login') },
      { name: 'register', path: '/sign-up', component: () => import('@/views/auth/Register') },
      { name: 'confirmAccount', path: '/sign-up/success', component: () => import('@/views/auth/RegisterSuccess') },
      { name: 'confirmAccountEndpoint', path: '/confirm-account', component: () => import('@/views/auth/ConfirmAccount') },
      { name: 'activateAccountEndpoint', path: '/activate-account', component: () => import('@/views/auth/ActivateAccount') },
      { name: 'activateAccountSuccess', path: '/activate-account/success', component: () => import('@/views/auth/ActivateAccountSuccess') },

      // Forgot password journey
      { name: 'forgotPassword', path: '/forgot-password', component: () => import('@/views/auth/ForgotPassword') },
      { name: 'forgotPasswordSuccess', path: '/forgot-password/success', component: () => import('@/views/auth/ForgotPasswordSuccess') },
      { name: 'setPassword', path: '/set-password', component: () => import('@/views/auth/SetPassword') },
      { name: 'setPasswordSuccess', path: '/set-password/success', component: () => import('@/views/auth/SetPasswordSuccess') },

      // OAUTH
      { name: 'oauthCallback', path: '/oauth-callback', component: () => import('@/views/auth/OAuthCallback') }

    ]
  },
  {
    path: '/legal',
    component: () => import('@/layouts/Legal'),
    children: [
      { name: 'privacy', path: 'privacy', component: () => import('@/views/static/Privacy') },
      { name: 'termsAndConditions', path: 'terms-and-conditions', component: () => import('@/views/static/TermsAndConditions') }
    ]
  },
  {
    // the error route
    path: '/:error(\\d+)', name: 'error', component: () => import('@/views/error/Error-1.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ selector: to.hash, behavior: 'smooth' })
        } else {
          resolve({ x: 0, y: 0 })
        }
      }, 500)
    })
  },
  routes
})

export default router
