export function getUserProgress (state) {
  return state.userProgress
}

export function getObjectivesReportUrl (state) {
  return state.objectivesReportUrl
}

export function getCareerReportUrl (state) {
  return state.careerReportUrl
}

export function getLabsReportUrl (state) {
  return state.labsReportUrl
}

export function getAvailableCertificates (state) {
  return state.availableCertificates
}

export function isLoading (state) {
  return state.loading
}
