export default function () {
  return {
    labs: [],
    labDetail: {
      machines: []
    },
    recentLabs: [],
    currentLabMachine: {
      name: '',
      pills: [
      ],
      hintsRevealed: [],
      lab: {
        name: ''
      },
      os: ''
    },
    vpnConnectionStatus: {
      state: false
    },
    loading: false
  }
}
