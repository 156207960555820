
export function getAnnouncements (state) {
  return state.announcements
}

export function getFaqs (state) {
  return state.faqs
}

export function getAbilitiesMitre (state) {
  return state.abilities_mitre
}

export function getAbilitiesNice (state) {
  return state.abilities_nice
}

export function isLoading (state) {
  return state.loading
}

export function getClientSubdomain (state) {
  return state.clientSubdomain
}

export function isClientPlatform (state) {
  return state.clientSubdomain !== ''
}

export function getHomeStats (state) {
  return state.homeStats
}

export function getWorkRolesNice (state) {
  return state.work_roles_nice
}

export function getDummyProfilePic (state) {
  return state.dummyProfilePic
}
