import { getter } from '@/services/store/utils'

import ApiService from '@/services/api.service'
import URLS from '@/config/urls'

export function getPills (context, params) {
  return getter(context, URLS.API.PILLS.GET_PILLS, 'setPills', params)
}

export function getRecentsPills (context, params) {
  return getter(context, URLS.API.PILLS.GET_RECENTS, 'setRecentsPills', params)
}

export function getPillDetail (context, id) {
  return getter(context, URLS.API.CATEGORIES.GET_PILL_DETAIL(id), 'setPillDetail')
}

export function getSmallPillDetail (context, id) {
  context.commit('startLoading')
  context.commit('setPillDetail', {})
  return ApiService.query(URLS.API.CATEGORIES.GET_SMALL_PILL_DETAIL(id))
    .then(({ data }) => {
      context.commit('setPillDetail', data)
    })
    .then(_ => { context.commit('endLoading') })
}

export function getExecutionPillDetail (context, id) {
  context.commit('startLoading')
  context.commit('setPillDetail', {})
  return ApiService.query(URLS.API.CATEGORIES.GET_EXECUTION_PILL_DETAIL(id))
    .then(({ data }) => {
      context.commit('setPillDetail', data)
    })
    .then(_ => { context.commit('endLoading') })
}

export function getPillResourceUrl (context, params) {
  context.commit('startLoading')
  return ApiService.query(URLS.API.PILLS.GET_PILL_RESOURCE(params.pill, params.resource))
    .then(response => {
      context.commit('endLoading')
      if (response.status === 200 && response.data.status !== 'ok') throw new Error()

      return response.data.data
    })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function startPill (context, id) {
  context.commit('startLoading')
  return ApiService.post(URLS.API.PILLS.START_PILL_EXECUTION(id))
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

// Pill Machine Actions
export function startMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.PILLS.MACHINE.START_MACHINE(params.pill, params.machine))
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function restartMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.PILLS.MACHINE.RESTART_MACHINE(params.pill, params.machine))
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function revertMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.PILLS.MACHINE.REVERT_MACHINE(params.pill, params.machine))
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function stopMachine (context, params) {
  context.commit('startLoading')
  return ApiService
    .post(URLS.API.PILLS.MACHINE.STOP_MACHINE(params.pill, params.machine))
    .then(_ => { context.commit('endLoading') })
    .catch(err => {
      context.commit('endLoading')
      throw err
    })
}

export function insertFlag (context, params) {
  context.commit('startLoading')
  return ApiService.post(URLS.API.PILLS.MACHINE.INSERT_FLAG(params.pill, params.machine), { flag: params.flag })
    .then(response => {
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      return err
    })
}

export function unlockHint (context, params) {
  context.commit('startLoading')
  return ApiService.post(URLS.API.PILLS.MACHINE.UNLOCK_HINT(params.pill, params.machine))
    .then(response => {
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      return err
    })
}

export function addRevealedHint (context, params) {
  context.commit('addRevealedHint', params)
}

export function updatePillMachines (context, id) {
  context.commit('startLoading')
  return ApiService.query(URLS.API.CATEGORIES.GET_EXECUTION_PILL_DETAIL(id))
    .then(response => {
      context.commit('setPillDetailVms', response.data.vms)
      context.commit('endLoading')
      return response
    })
    .catch(err => {
      context.commit('endLoading')
      return err
    })
}
