export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function setCategories (state, categories) {
  state.categories = categories
}

export function setCategoryDetail (state, categoryDetail) {
  state.categoryDetail = categoryDetail
}

export function setCompletedCategoriesNice (state, completedCategoriesNice) {
  state.completedCategoriesNice = completedCategoriesNice
}

export function setCompletedCategoriesMitre (state, completedCategoriesMitre) {
  state.completedCategoriesMitre = completedCategoriesMitre
}

export function setWorkRoleDetail (state, workRoleDetail) {
  state.workRoleDetail = workRoleDetail
}
