export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function setObjectives (state, objectives) {
  state.objectives = objectives
}

export function setObjectivesFirstLevel (state, objectivesFirstLevel) {
  state.objectivesFirstLevel = objectivesFirstLevel
}

export function setObjectiveDetail (state, objectiveDetail) {
  state.objectiveDetail = objectiveDetail
}
