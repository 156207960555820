import { getter } from '@/services/store/utils'
import URLS from '@/config/urls'
import ApiService from '@/services/api.service'

export function getAnnouncements (context) {
  return getter(context, URLS.API.STATIC.GET_ANNOUNCEMENTS, 'setAnnouncements')
}

export function getFaqs (context) {
  if (context.getters.getFaqs.length > 0) return new Promise(resolve => { resolve() })

  return getter(context, URLS.API.STATIC.GET_FAQS, 'setFaqs')
}

export function getAbilitiesNice (context) {
  if (context.getters.getAbilitiesNice.length > 0) return new Promise(resolve => { resolve() })

  return getter(context, URLS.API.STATIC.GET_CATEGORIES_NICE, 'setAbilitiesNice')
}

export function getAbilitiesMitre (context) {
  if (context.getters.getAbilitiesMitre.length > 0) return new Promise(resolve => { resolve() })

  return getter(context, URLS.API.STATIC.GET_CATEGORIES_MITRE, 'setAbilitiesMitre')
}

export function setClientSubdomain (context, subdomain) {
  context.commit('setClientSubdomain', subdomain)
}

export function getHomeStats (context) {
  context.commit('startLoading')

  return ApiService.query(URLS.API.STATS.HOME_STATS)
    .then(response => {
      if (response.status !== 200 || response.data.status !== 'ok') throw response
      context.commit('setHomeStats', response.data.data)
      context.commit('endLoading')
      return response.data
    })
    .catch(error => {
      context.commit('endLoading')
      throw error
    })
}
