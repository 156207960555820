export function setUserProgress (state, userProgress) {
  state.userProgress = userProgress
}

export function setObjectivesReportUrl (state, objectivesReportUrl) {
  state.objectivesReportUrl = objectivesReportUrl
}

export function setCareerReportUrl (state, careerReportUrl) {
  state.careerReportUrl = careerReportUrl
}

export function setLabsReportUrl (state, labsReportUrl) {
  state.labsReportUrl = labsReportUrl
}

export function setAvailableCertificates (state, availableCertificates) {
  state.availableCertificates = availableCertificates
}

export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}
