import store from '@/services/store/index'

/*
  Comprobamos si estamos accediendo a un front de cliente. En caso de ser así, almacenamos el subdominio
  y lo usamos en todas las peticiones
*/
let subdomain = ''
var splitted = window.location.hostname.split('.')

const urlIsIp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(window.location.hostname)

if (!urlIsIp && process.env.VUE_APP_BASE_URL !== window.location.hostname && splitted.length > 1) {
  store.commit('staticInfo/setClientSubdomain', splitted[0])
  subdomain = splitted[0]
}

export const PROTOCOL = process.env.VUE_APP_API_PROTOCOL
export const PORT = process.env.VUE_APP_API_PORT
export const BASE_URL = `${PROTOCOL}://${subdomain !== '' ? `${subdomain}.` : ''}${process.env.VUE_APP_API_BASE_URL}${PORT !== 80 ? (':' + PORT) : ''}`

const buildUrl = (path) => `${BASE_URL}/api/v1${path}`
const buildGeneralUrl = (path) => `${BASE_URL}${path}`

export default {
  BASE_URL: BASE_URL,
  API: {
    AUTH: {
      LOGIN_URL: buildGeneralUrl('/api/login_check'),
      SIGN_UP: buildUrl('/auth/register'),
      RESEND_CONFIRMATION_LINK: buildUrl('/student/resend-confirmation'),
      VALIDATE_CONFIRMATION_TOKEN: buildUrl('/student/confirm-account'),
      FORGOT_PASSWORD: buildUrl('/auth/forgot-password'),
      SET_PASSWORD: buildUrl('/auth/set-password'),
      ACTIVATE_ACCOUNT: buildUrl('/auth/activate-account'),

      // LOG OUT
      REFRESH_TOKEN: buildUrl('/token-refresh'),
      LOGOUT: buildUrl('/logout'),

      // OAUTH
      OAUTH_SIGNIN: buildUrl('/auth/oauth-signin'),
      OAUTH_CALLBACK: buildUrl('/auth/oauth-callback')

    },
    ACADEMY: {
      GET_COURSES: buildUrl('/courses'),
      GET_COURSE_DETAIL: (id) => buildUrl(`/courses/${id}`)
    },
    CATEGORIES: {
      GET_CATEGORIES: buildUrl('/categories'),
      GET_CATEGORY_DETAIL: (id) => buildUrl(`/categories/${id}`),
      GET_WORK_ROLE_DETAIL: (id) => buildUrl(`/work_roles_nice/${id}`),
      GET_PILL_DETAIL: (id) => buildUrl(`/pills/${id}`),
      GET_SMALL_PILL_DETAIL: (id) => buildUrl(`/pills/small-detail/${id}`),
      GET_EXECUTION_PILL_DETAIL: (id) => buildUrl(`/pills/execution-detail/${id}`)
    },
    LABS: {
      GET_LABS: buildUrl('/labs'),
      GET_RECENT_LABS: buildUrl('/labs/recent'),
      GET_LAB_DETAIL: (id) => buildUrl(`/labs/${id}`),

      MACHINE: {
        GET_DETAIL: (lab, machine) => buildUrl(`/labs/${lab}/machine/${machine}`),
        UNLOCK_HINT: (lab, machine) => buildUrl(`/labs/${lab}/machine/${machine}/unlock-hint`),
        INSERT_FLAG: (lab, machine) => buildUrl(`/labs/${lab}/machine/${machine}/insert-flag`),
        SUBMIT_ACTION: (lab, machine) => buildUrl(`/labs/${lab}/machine/${machine}/action`),
        START_MACHINE: (lab, machine) => buildUrl(`/labs/${lab}/machine/${machine}/start`),
        RESTART_MACHINE: (lab, machine) => buildUrl(`/labs/${lab}/machine/${machine}/start`),
        REVERT_MACHINE: (lab, machine) => buildUrl(`/labs/${lab}/machine/${machine}/revert`),
        STOP_MACHINE: (lab, machine) => buildUrl(`/labs/${lab}/machine/${machine}/stop`)
      },

      CONSOLE: {
        START: buildUrl('/student/console/start'),
        RESTART: buildUrl('/student/console/reboot'),
        STOP: buildUrl('/student/console/stop'),
        REVERT: buildUrl('/student/console/revert'),
        STATUS: buildUrl('/student/console/status')
      },

      VPN: {
        // DOWNLOAD_PROFILE: (id) => buildUrl(`/labs/${id}/vpn/profile`),
        // GET_VPN_CONNECTION_STATUS: (id) => buildUrl(`/labs/${id}/vpn/status`)
        DOWNLOAD_PROFILE: buildUrl('/student/vpn/profile'),
        GET_VPN_CONNECTION_STATUS: buildUrl('/student/vpn/status')
      }
    },
    PILLS: {
      GET_PILLS: buildUrl('/pills'),
      GET_RECENTS: buildUrl('/pills/recent'),
      SUBMIT_PILL: (id) => buildUrl(`/pills/${id}/questions/submit`),
      START_PILL_EXECUTION: (id) => buildUrl(`/pills/${id}/execute`),
      GET_PILL_RESOURCE: (pill, resource) => buildUrl(`/pills/${pill}/resource/${resource}`),

      MACHINE: {
        GET_DETAIL: (pill, machine) => buildUrl(`/pills/${pill}/machine/${machine}`),
        UNLOCK_HINT: (pill, machine) => buildUrl(`/pills/${pill}/machine/${machine}/unlock-hint`),
        INSERT_FLAG: (pill, machine) => buildUrl(`/pills/${pill}/machine/${machine}/insert-flag`),
        SUBMIT_ACTION: (pill, machine) => buildUrl(`/pills/${pill}/machine/${machine}/action`),
        START_MACHINE: (pill, machine) => buildUrl(`/pills/${pill}/machine/${machine}/start`),
        RESTART_MACHINE: (pill, machine) => buildUrl(`/pills/${pill}/machine/${machine}/start`),
        REVERT_MACHINE: (pill, machine) => buildUrl(`/pills/${pill}/machine/${machine}/revert`),
        STOP_MACHINE: (pill, machine) => buildUrl(`/pills/${pill}/machine/${machine}/stop`)
      }
    },
    USER: {
      GET_PROGRESS: buildUrl('/student/progress'),
      GET_STUDENT: buildUrl('/student'),
      UPDATE_STUDENT: buildUrl('/student/profile/update-profile'),
      ADD_CERTIFICATION: buildUrl('/student/add_certification'),
      GET_OBJECTIVES_REPORT: buildUrl('/reports/objective'),
      GET_CAREER_REPORT: buildUrl('/reports/career'),
      GET_LABS_REPORT: buildUrl('/reports/labs'),
      GET_COMPLETED_CATEGORIES_NICE: buildUrl('/categories_nice/completed'),
      GET_COMPLETED_CATEGORIES_MITRE: buildUrl('/categories_mitre/completed'),

      GET_STUDENT_PROFILE_PIC: buildUrl('/student/profile/get-profile-image'),
      SET_STUDENT_PROFILE_PIC: buildUrl('/student/profile/update-profile-image'),

      STATS: {
        LAST_WEEK_COMPLETED_PILLS: buildUrl('/student/last-week-pills')
      },

      MACHINE: {
        SUBMIT_ACTION: buildUrl('/student/machine/action'),
        STOP_OR_UNLOCK: (id) => buildUrl(`/student/instance/${id}/terminate`)
      },
      ISSUES: {
        SUBMIT_ISSUE: buildUrl('/student/bug-report')
      }
    },
    CAREERS: {
      GET_CAREERS: buildUrl('/categories_nice'),
      GET_CAREER_DETAIL: (id) => buildUrl(`/categories_nice/${id}`)
    },
    OBJECTIVES: {
      GET_OBJECTIVES: buildUrl('/objectives'),
      GET_OBJECTIVES_FIRST_LEVEL: buildUrl('/objectives/first_level'),
      GET_OBJECTIVE_DETAIL: (id) => buildUrl(`/objectives/${id}`)
    },
    CERTIFICATES: {
      GET_AVAILABLE_CERTIFICATES: buildUrl('/certifications')
    },
    STATIC: {
      GET_ANNOUNCEMENTS: buildUrl('/announcements'),
      GET_FAQS: buildUrl('/faqs'),
      GET_CATEGORIES_MITRE: buildUrl('/categories_mitre'),
      GET_CATEGORIES_NICE: buildUrl('/categories_nice'),
      GET_IMG: (name) => buildGeneralUrl(`/uploads/${name}`),
      CUSTOM_CSS: buildUrl('/auth/ccss')
    },
    STATS: {
      HOME_STATS: buildUrl('/home/categories-stats')
    },
    CONFIG: {
      AUTH: buildUrl('/auth/config'),
      GENERAL: buildUrl('/home/config')
    }
  }
}
