
export function getCourses (state) {
  return state.courses
}

export function getCourseDetail (state) {
  return state.courseDetail
}

export function isLoading (state) {
  return state.loading
}
