export function isLoading (state) {
  return state.loading
}

export function getCareers (state) {
  return state.careers
}

export function getCareerDetail (state) {
  return state.careerDetail
}
