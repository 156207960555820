import { getter } from '@/services/store/utils'
import URLS from '@/config/urls'

export function getCategories (context) {
  return getter(context, URLS.API.CATEGORIES.GET_CATEGORIES, 'setCategories')
}

export function getCategoryDetail (context, id) {
  return getter(context, URLS.API.CATEGORIES.GET_CATEGORY_DETAIL(id), 'setCategoryDetail')
}

export function getWorkRoleDetail (context, id) {
  return getter(context, URLS.API.CATEGORIES.GET_WORK_ROLE_DETAIL(id), 'setWorkRoleDetail')
}

export function getCompletedCategoriesNice (context) {
  return getter(context, URLS.API.USER.GET_COMPLETED_CATEGORIES_NICE, 'setCompletedCategoriesNice')
}

export function getCompletedCategoriesMitre (context) {
  return getter(context, URLS.API.USER.GET_COMPLETED_CATEGORIES_MITRE, 'setCompletedCategoriesMitre')
}
