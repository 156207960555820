export function startLoading (state) {
  state.loading = true
}

export function endLoading (state) {
  state.loading = false
}

export function setGeneralConfig (state, config) {
  state.canSeeUserConsole = config.terminal || false
  state.canSeeVpn = config.vpn || false
}

export function setAuthConfig (state, authConfig) {
  state.authConfig.useSignUpToken = authConfig?.use_sign_up_token || false
}

export function setVpnConnectionStatus (state, vpnStatus) {
  state.vpnStatus = vpnStatus
}
