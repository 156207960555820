export function isLoading (state) {
  return state.loading
}

export function getPills (state) {
  return state.pills
}

export function getPillDetail (state) {
  return state.pillDetail
}

export function getRecentsPills (state) {
  return state.recentPills
}
