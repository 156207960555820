import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Localisation language list
import { locale as en } from '@/config/i18n/en'
import { locale as de } from '@/config/i18n/de'
import { locale as es } from '@/config/i18n/es'

Vue.use(VueI18n)

let messages = {}
messages = { ...messages, en, de, es }

const availableLangs = process.env.VUE_APP_AVAILABLE_LANGS || 'es,en'
const availableLangsList = availableLangs.split(',')

// get current selected language
const lang = localStorage.getItem('language') || availableLangsList[0]

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
})

export default i18n
